import { defineStore } from "pinia";
import axios from "axios";

export const useWorkspaceStore = defineStore("workspace", {
  state: () => ({
    id: null,
    collaborators: [],
    owner: "",
    permission: "",
    projects: [],
    title: "",
    logo: null,
    created_at: "",
    errorMessage: null,
    workspaceInfo: {},
    workspaces: [],
    working: false,
  }),

  actions: {
    async fetchWorkspace() {
      try {
        const response = await axios.get(`/workspace/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const activeWorkspace = response.data.find((ws) => ws.working); // Find the active workspace
        if (activeWorkspace) {
          this.id = activeWorkspace.id;
          this.collaborators = activeWorkspace.collaborators;
          this.owner = activeWorkspace.owner;
          this.permission = activeWorkspace.permission;
          this.projects = activeWorkspace.projects;
          this.title = activeWorkspace.title;
          this.logo = activeWorkspace.logo;
          this.created_at = activeWorkspace.created_at;
          this.working = activeWorkspace.working;
        }
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to fetch workspace";
      }
    },

    async fetchAllWorkspaces() {
      try {
        const response = await axios.get(`/workspace/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.workspaces = response.data.map((ws) => ({
          ...ws,
          working: ws.working,
        }));
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to fetch workspaces";
      }
    },

    async updateWorkspace(updatedData) {
      try {
        const response = await axios.put(
          `/workspace/${this.id}/`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        Object.assign(this, response.data);
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to update workspace";
      }
    },
    async deleteWorkspace() {
      try {
        await axios.delete(`/workspace/${this.id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to delete workspace";
      }
    },
    async uploadLogo(formData) {
      try {
        formData.append("title", this.title);
        const response = await axios.put(`/workspace/${this.id}/`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "multipart/form-data",
          },
        });
        this.logo = `${response.data.logo}`; // Update logo in store
      } catch (error) {
        console.error("Error uploading logo:", error.response?.data);
        this.errorMessage =
          error.response?.data?.detail || "Failed to upload logo";
      }
    },

    async deleteLogo() {
      try {
        await axios.put(
          `/workspace/${this.id}/`,
          { title: this.title, logo: null },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.logo = null; // Remove logo in store
      } catch (error) {
        console.error("Error deleting logo:", error.response?.data);
        this.errorMessage =
          error.response?.data?.detail || "Failed to delete logo";
      }
    },
    async createWorkspace(data) {
      try {
        const response = await axios.post("/workspace/", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        this.workspaces.push(response.data); // Update state
        return response.data;
      } catch (error) {
        console.error("Failed to create workspace:", error);
        throw error;
      }
    },
    async setActiveWorkspace(workspaceId) {
      try {
        // Set the selected workspace as active on the server
        await axios.put(
          `/workspace/${workspaceId}/`,
          { working: true },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        // Find the selected workspace in the local state
        const activeWorkspace = this.workspaces.find(
          (ws) => ws.id === workspaceId
        );

        if (activeWorkspace) {
          // Update the state with the active workspace details
          this.id = activeWorkspace.id;
          this.title = activeWorkspace.title;
          this.logo = activeWorkspace.logo;
          this.collaborators = activeWorkspace.collaborators;
          this.owner = activeWorkspace.owner;
          this.permission = activeWorkspace.permission;
          this.projects = activeWorkspace.projects;
          this.created_at = activeWorkspace.created_at;

          // Update the working status of all workspaces in the local state
          this.workspaces = this.workspaces.map((ws) => ({
            ...ws,
            working: ws.id === workspaceId, // Only the selected workspace is active
          }));
        }
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to set active workspace";
      }
    },
  },
});
