<template>
  <div
    class="card flex justify-between items-center w-full mt-9"
    @click="handleParentClick"
  >
    <img
      :src="workspaceStore.logo || require('@/assets/blank.png')"
      alt="pic"
      class="w-14 h-14 rounded-full overflow-clip maintain-aspect-ratio "
    />
    <div class="w-full flex flex-col items-start justify-center p-4">
      <div class="text-lg font-bold">
        {{ workspaceStore.title || "Default Workspace" }}
      </div>
      <div class="text-md text-gray-400/60">
        {{ authStore.userInfo.name || "Guest" }}
      </div>
    </div>
    <Button
      id="dropdownButton"
      class="border-none bg-transparent hover:bg-gray-400 rounded-full transition"
      type="button"
      icon="pi pi-angle-down"
      label=""
      @click="toggleDropdown"
    />

    <Popover
      ref="dropdownPopover"
      class="bg-gray-900 border-gray-700 shadow-black shadow-2xl text-white"
    >
      <div class="flex flex-col gap-4 w-[20rem] p-4">
        <div class="flex items-center justify-between">
          <div class="flex flex-col">
            <img
              :src="workspaceStore.logo || require('@/assets/blank.png')"
              alt="Workspace Image"
              class="w-10 h-10 rounded-full"
            />
            <div>
              <div class="font-bold">
                {{ workspaceStore.title }}'s workspace
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-700 my-4"></div>
        <div class="flex flex-col gap-2">
          <div
            v-for="workspace in workspaceStore.workspaces"
            :key="workspace.id"
            class="flex items-center gap-2"
          >
            <img
              :src="workspace.logo || require('@/assets/blank.png')"
              alt="Workspace Icon"
              class="w-8 h-8 rounded-full"
            />
            <div class="flex-1">{{ workspace.title }}</div>
            <i v-if="workspace.working" class="pi pi-check text-blue-500"></i>
            <Button
              class="text-white hover:text-blue-500 p-button-primary bg-blue-600 mt-2 border-transparent"
              label="Select"
              @click="changeActiveWorkspace(workspace.id)"
              style="padding: 4px !important; font-size: 14px"
            />
          </div>
          <Button
            label="New Workspace"
            icon="pi pi-plus"
            class="w-full p-button-sm p-button-primary bg-blue-600 mt-2 border-transparent"
            @click="openNewWorkspaceModal"
          />
        </div>
      </div>
    </Popover>

    <Dialog
      v-model:visible="isNewWorkspaceModalVisible"
      header="New Workspace"
      :modal="true"
      :closable="true"
      class="w-[20rem] bg-gray-900 border-transparent shadow-black shadow-2xl text-white"
      :headerStyle="{ paddingLeft: '20px' }"
      @hide="closeDialog"
      style="padding: 20px !important"
    >
      <div class="flex flex-col gap-4 p-5">
        <div>
          <label for="workspaceName" class="block text-sm mb-2"
            >Workspace Name</label
          >

          <InputText
            id="workspaceName"
            v-model="newWorkspaceName"
            class="w-full bg-transparent border-gray-400/70 text-gray-400"
          />
        </div>
        <div>
          <label for="workspaceIcon" class="block text-sm mb-2">
            Workspace Icon
          </label>
          <div class="flex items-center gap-2">
            <!-- Avatar -->
            <Avatar
              v-if="uploadedImageUrl"
              :image="uploadedImageUrl"
              class="mr-2"
            />
            <Avatar
              v-else
              :label="uploadedFileName ? uploadedFileName.charAt(0) : 'W'"
              class="mr-2"
            />

            <!-- Loading Indicator -->
            <i v-if="isLoading" class="pi pi-spinner pi-spin"></i>

            <!-- Button to Trigger File Input -->
            <Button
              label="Choose Photo"
              icon="pi pi-upload"
              class="bg-blue-600 border-transparent"
              @click="triggerFileInput"
              style="padding: 6px !important; font-size: 12px; margin-top: 2px"
            />
            <input
              type="file"
              accept="image/*"
              class="hidden"
              ref="fileInput"
              @change="handleFileChange"
            />
          </div>
        </div>
        <Button
          label="Create"
          :disabled="isCreatingWorkspace"
          class="w-full bg-blue-600 mt-2 border-transparent flex items-center justify-center"
          @click="createNewWorkspace"
          style="padding: 4px !important"
        >
          <i v-if="isCreatingWorkspace" class="pi pi-spinner pi-spin mr-2"></i>
          <span v-else>Create</span>
        </Button>
      </div>
    </Dialog>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import Popover from "primevue/popover";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Avatar from "primevue/avatar";
import { useWorkspaceStore } from "@/stores/workspace";
import { useAuthStore } from "@/stores/auth";

// Stores
const workspaceStore = useWorkspaceStore();
const authStore = useAuthStore();

// Refs and data
const dropdownPopover = ref();
const isNewWorkspaceModalVisible = ref(false);
const newWorkspaceName = ref("");
const uploadedFile = ref(null); // Holds the uploaded file
const uploadedFileName = ref(""); // Holds the file name for display
const uploadedImageUrl = ref(null); // Stores the preview URL for the uploaded image
const isLoading = ref(false); // Tracks loading state during upload
const workspaces = ref([]);
const isCreatingWorkspace = ref(false); // Tracks loading state for workspace creation

// Methods
const closeDialog = () => {
  isNewWorkspaceModalVisible.value = false;
};

const toggleDropdown = (event) => {
  console.log(event);
  dropdownPopover.value.toggle(event);
};
const handleParentClick = (event) => {
  if (!event.target.closest("#dropdownButton")) {
    dropdownPopover.value.toggle(event);
  }
};
const openNewWorkspaceModal = () => {
  isNewWorkspaceModalVisible.value = true;
};

const triggerFileInput = () => {
  document.querySelector("input[type='file']").click();
};

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;
    uploadedFileName.value = file.name;

    // Show loading indicator
    isLoading.value = true;

    // Create a preview URL for the uploaded file
    const reader = new FileReader();
    reader.onload = (e) => {
      uploadedImageUrl.value = e.target.result;
      isLoading.value = false; // Hide loading indicator after image loads
    };
    reader.readAsDataURL(file);
  }
};

const createNewWorkspace = async () => {
  try {
    isCreatingWorkspace.value = true; // Start loading
    const formData = new FormData();
    formData.append("title", newWorkspaceName.value);
    if (uploadedFile.value) {
      formData.append("logo", uploadedFile.value);
    }

    const newWS = await workspaceStore.createWorkspace(formData);
    await workspaceStore.setActiveWorkspace(newWS.id);
    isNewWorkspaceModalVisible.value = false; // Close modal on success
    uploadedFile.value = null; // Reset uploaded file
    uploadedImageUrl.value = null; // Reset image preview
  } catch (error) {
    console.error("Error creating workspace:", error);
  } finally {
    isCreatingWorkspace.value = false; // Stop loading
  }
};

const changeActiveWorkspace = async (workspaceId) => {
  try {
    await workspaceStore.setActiveWorkspace(workspaceId);
    await workspaceStore.fetchAllWorkspaces(); // Refresh the list to update the UI
  } catch (error) {
    console.error("Failed to change active workspace:", error);
  }
};

// Lifecycle hook
onMounted(async () => {
  try {
    workspaces.value = await workspaceStore.fetchAllWorkspaces(); // Fetch all workspaces on mount
  } catch (error) {
    console.error("Error fetching workspaces:", error);
  }
});
</script>
