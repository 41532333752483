<template>
  <DashboardBody>
    <div class="flex flex-col w-full h-full">
      <!-- header -->
      <div class="w-full pt-5 pb-2 px-9 flex justify-between">
        <SearchBar :onChange="search" placeholder="Search Projects" />
      </div>
      <!-- end header  -->
      <!-- data table -->
      <div class="w-full h-full">
        <div class="px-5 py-2">
          <DataTable
            size="large"
            :value="fileStore.workspace_files"
            paginator
            :rows="6"
            tableStyle="min-width: 50rem"
          >
            <template #paginatorprevpagelinkicon> Previous </template>
            <template #paginatornextpagelinkicon> Next </template>
            <Column field="id"></Column>
            <Column field="title" header="Project Title"></Column>
            <Column header="Type">
              <template #body="slot">
                <div class="mx-2 my-1 text-sm">
                  <SoundHigh v-if="slot.data.media_type == 'audio'" />
                  <MediaVideo v-else />
                </div>
              </template>
            </Column>
            <Column field="durations" header="Length"></Column>
            <Column header="Date Added">
              <template #body="slot">
                {{ trimStringAtT(slot.data.uploaded_at) }}
              </template>
            </Column>
            <Column header="Action">
              <template #body="slot">
                <Button
                  v-if="slot != null"
                  @click="(event) => toggleAction(event, slot.data.id)"
                  class="pi pi-ellipsis-v cursor-pointer rounded-lg bg-blue-600 disabled:bg-gray-700 flex flex-col items-center justify-center w-8 h-8"
                  aria-haspopup="true"
                  :disabled="slot.data.status == 'in_progress'"
                  :aria-controls="`menu-${slot.data.id}`"
                >
                </Button>
                <Menu
                  v-if="slot != null"
                  class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800"
                  :ref="(el) => (actionState[`menu-${slot.data.id}`] = el)"
                  :id="`menu-${slot.data.id}`"
                  :model="actionItems"
                  :popup="true"
                >
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full gap-2 px-3 py-1 cursor-pointer"
                      @click="item.onClick(slot.data.service, slot.data.id)"
                    >
                      <span :class="item.icon" />
                      <a
                        v-if="item.label == 'download'"
                        :href="slot.data.file"
                        >{{ item.label }}</a
                      >
                      <span v-else>{{ item.label }}</span>
                    </div>
                  </template>
                </Menu>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <!-- end data table -->
    </div>

    <Dialog
      v-model:visible="renameDialogVisibel"
      modal
      header="Edit File Name"
      :pt="{
        root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
        header: 'm-5',
        content: 'mx-5 flex items-center justify-center my-5',
        mask: '!bg-black/50   backdrop-blur-sm',
      }"
      dismissableMask
    >
      <InputText @change="updateTitle" v-model="newName" />
    </Dialog>
  </DashboardBody>
</template>

<script setup>
import DashboardBody from "@/components/DashboardBody.vue";
import SearchBar from "@/components/SearchBar.vue";

import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
// import Button from "primevue/button";
import Menu from "primevue/menu";
import { ref, onMounted, watch } from "vue";
import { useWorkspaceStore } from "@/stores/workspace";
import { useFileStore } from "@/stores/file";
import { SoundHigh, MediaVideo } from "@iconoir/vue";
import { trimStringAtT } from "@/utils";

const workspaceStore = useWorkspaceStore();
const fileStore = useFileStore();

const fetchData = async () => {
  await workspaceStore.fetchWorkspace();
  await fileStore.fetchWorkspcae(workspaceStore.id);
};

onMounted(async () => {
  console.log(workspaceStore.id);
  await fetchData();
  watch(
    () => workspaceStore.id,
    async () => {
      await fetchData();
    }
  );
});

const updateTitle = async () => {
  // await projectStore.fetch(projectIdToRename.value);
  // await projectStore.updateTitle(projectIdToRename.value, newName.value);
  // await fetchData();
};
const fileIdToRename = ref();
const renameDialogVisibel = ref(false);
const newName = ref();
const onActionRename = (_, id) => {
  renameDialogVisibel.value = true;
  fileIdToRename.value = id;
};
const actionItems = ref([
  {
    items: [
      {
        label: "download",
        icon: "pi pi-cloud-download",
        onClick: () => {},
      },
      {
        label: "Share",
        icon: "pi pi-share-alt",
        onClick: () => {},
      },
      {
        label: "Rename",
        icon: "pi pi-pencil",
        onClick: onActionRename,
      },
      {
        label: "Transfer",
        icon: "pi pi-reply",
        onClick: () => {},
      },
      {
        label: "Move To Trash",
        icon: "pi pi-trash",
        onClick: () => {},
      },
    ],
  },
]);

const actionState = ref({});
const toggleAction = (event, id) => {
  const menuRef = `menu-${id}`;
  const menuInstance = actionState.value[menuRef];
  if (menuInstance && typeof menuInstance.toggle === "function") {
    menuInstance.toggle(event);
  } else {
    console.error(
      `Menu instance for ${menuRef} not found or toggle is not a function.`
    );
  }
};
</script>

<style>
:root {
  --p-menu-item-color: #6f767e;
  --p-paginator-nav-button-selected-background: #292929;
  --p-datatable-header-border-color: #292929;
  --p-datatable-header-cell-background: #00000000;
  --p-datatable-row-background: #00000000;
  --p-datatable-header-cell-color: #6f767e;
  --p-datatable-row-color: #f1f2f3;
  --p-datatable-border-color: #272b30;
}

.p-paginator {
  @apply bg-transparent text-gray-400 my-3;
}

.p-paginator-first {
  @apply hover:bg-transparent;
}

.p-paginator-last {
  @apply hover:bg-transparent;
}

.p-paginator .p-paginator-page {
  @apply hover:bg-gray-800/40 rounded-lg text-gray-400;
}

::v-deep .p-selectbutton {
  background-color: #00000000 !important;
}

.p-inputtext {
  @apply bg-transparent !px-3 !py-1 text-white;
}
</style>
