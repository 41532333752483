import { createRouter, createWebHistory } from "vue-router";
import SignUpPage from "@/pages/SignUpPage.vue";
import SignInPage from "@/pages/SignInPage.vue";
import SignUpPageTwo from "@/pages/SignUpPageTwo.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import ResetPasswordTwo from "@/pages/ResetPasswordTwo.vue";
import NewPassword from "@/pages/NewPassword.vue";
import AdminDashboardPage from "@/pages/dashboard/admin/AdminDashboardPage.vue";
import { adminDashboardChildren } from "./dashboard";
import TranscriptionEditorPage from "@/pages/editor/TranscriptionEditorPage.vue";
import CaptionEditorPage from "@/pages/editor/CaptionEditorPage.vue";
import InvitationSuccess from "@/pages/InvitationSuccess.vue";
import { useAuthStore } from "@/stores/auth";

const routes = [
  {
    path: "/sign-up",
    name: "SignUpPage",
    component: SignUpPage,
  },
  {
    path: "/sign-up-two",
    name: "SignUpPageTwo",
    component: SignUpPageTwo,
  },
  {
    path: "/invite",
    name: "InvitationSuccess",
    component: InvitationSuccess,
  },
  {
    path: "/sign-in",
    name: "SignInPage",
    component: SignInPage,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/reset-password-two",
    name: "ResetPasswordTwo",
    component: ResetPasswordTwo,
  },
  {
    path: "/",
    redirect: "/sign-in", // Automatically redirect root to Sign In
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: NewPassword,
  },
  {
    path: "/admin",
    name: "AdminDashboard",
    component: AdminDashboardPage,
    children: adminDashboardChildren,
  },
  {
    path: "/transcription/:id",
    name: "Transcription",
    component: TranscriptionEditorPage,
  },
  {
    path: "/caption/:id",
    name: "Caption",
    component: CaptionEditorPage,
  },
];
const loadGoogleScript = () => {
  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

loadGoogleScript();
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = authStore.isAuthenticated(); // Assume this is a boolean indicating auth status

  // Allow access to login and sign-up pages without authentication
  if (
    to.name === "SignInPage" ||
    to.name === "SignUpPage" ||
    to.name === "SignUpPageTwo"
  ) {
    next();
  } else if (!isAuthenticated) {
    // Redirect to sign-in page if not authenticated
    next({ name: "SignInPage" });
  } else {
    // Allow access if authenticated
    next();
  }
});

export default router;
