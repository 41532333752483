<template>
<Toast />
  <div class="login-container">
    <div class="left-container">
      <div class="form-wrapper">
        <div class="logo">
          <h1><img style="vertical-align: middle;" src="@/assets/logo.svg" alt="typeteam logo" class="icon" />typeteam</h1>
          <h2 style='color:#FFFFFF;'>Sign in</h2>
          <p class="account-text">New to Typeteam? <a href="/sign-up">Sign up</a></p>
        </div>

        <div class="social-sign-in">

          <button @click="signInWithGoogle" class="social-button">
            <img src="@/assets/google-logo.svg" alt="Google" class="icon1" />
            Sign in with Google
          </button>
        </div>

        <div class="divider">
          <span class="line"></span>
          <span class="or-text">or</span>
          <span class="line"></span>
        </div>

        <div class="form">
          <label for="email" class="label">Email</label> 
          <input type="email" v-model="authStore.email" placeholder="Your Email" class="input-field" />

          <div class="password-wrapper" >
            <label for="password" class="label">Password</label>
            <a href="/reset-password" class="forgot-password-link">Forgot password?</a>
          </div>
          <input type="password" v-model="authStore.password" placeholder="Password" class="input-field" />

          <button @click="signIn" class="sign-in-button">Sign in</button>
        </div>
      </div>
    </div>

    <div class="right-container">
      <img src="@/assets/laptop-woman.png" alt="Woman with laptop" class="side-image" />
    </div>
  </div>
</template>

<script setup>
import '@/styles/login.css'; 
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import Toast from 'primevue/toast';


const toast = useToast();
const authStore = useAuthStore();
const router = useRouter();

const signIn = async () => {

    await authStore.login();
  if (authStore.errorMessage) {
    toast.add({ severity: 'error', summary: 'Sign in Error', detail: authStore.errorMessage, life: 3000 });
  } else {
    router.push('/admin');
  }
};
</script>


