function capitalizeFirstLetter(string) {
  if (!string) return ""; // Handle empty or undefined input
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function durationToSecs(string) {
  if (!string) return 0; // Handle empty or undefined input
  if (string == null || string == undefined) return 0; // Handle empty or undefined input
  const [hours, minutes, seconds] = string.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}
function secsToDuration(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const secondsFormatted = Math.floor(remainingSeconds);
  const millisecondsFormatted = Math.round(
    (remainingSeconds - secondsFormatted) * 100
  );

  // Ensure two digits for hours, minutes, seconds and two digits for milliseconds
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = secondsFormatted.toString().padStart(2, "0");
  const formattedMilliseconds = millisecondsFormatted
    .toString()
    .padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
}
function formatTime(time) {
  return secsToDuration(durationToSecs(time));
}
function trimStringAtT(inputString) {
  // Find the index of 'T'
  const index = inputString.indexOf("T");

  // If 'T' is found, return the substring before 'T'
  // If 'T' is not found, return the original string
  return index !== -1
    ? inputString.substring(0, index).trim()
    : inputString.trim();
}

function capitalizeFirstLetterAndReplaceUnderscore(inputString) {
  // Replace underscores with spaces
  if (inputString === null) return "";
  const stringWithSpaces = inputString.replace(/_/g, " ");
  // Capitalize the first letter of each word
  return stringWithSpaces.replace(/\b\w/g, (char) => char.toUpperCase());
}

function lowercaseAndReplaceSpaces(inputString) {
  // Convert the string to lowercase and replace spaces with underscores
  if (inputString === undefined) return null;
  return inputString.toLowerCase().replace(/ /g, "_");
}
export {
  capitalizeFirstLetter,
  durationToSecs,
  secsToDuration,
  trimStringAtT,
  capitalizeFirstLetterAndReplaceUnderscore,
  lowercaseAndReplaceSpaces,
  formatTime,
};
