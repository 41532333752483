<template>
  <Toast />
  <DashboardBody>
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="w-full pt-5 pb-2 px-9 flex justify-between">
        <SearchBar :onChange="search" placeholder="Search Projects" />
        <SelectButton
          class="dark p-selectbutton"
          :allowEmpty="false"
          v-model="value"
          :options="options"
          aria-labelledby="custom"
        >
          <template #option="slotProps">
            <RouterLink
              class="mx-1 font-bold p-2 text-sm text-gray-200 flex items-center gap-3"
              :to="{ path: route.path, query: { filter: slotProps.option } }"
            >
              {{ slotProps.option }}
            </RouterLink>
          </template>
        </SelectButton>
      </div>
      <!-- body -->
      <div class="w-full h-full">
        <div class="px-5 py-2">
          <DataTable
            size="large"
            :value="projectStore.projects"
            paginator
            sortField="created_at"
            :sortOrder="-1"
            :rows="6"
            tableStyle="min-width: 50rem"
          >
            <template #paginatorprevpagelinkicon> Previous </template>
            <template #paginatornextpagelinkicon> Next </template>
            <Column field="id"></Column>
            <Column header="Project Title">
              <template #body="slot">
                <div
                  class="cursor-pointer hover:underline hover:text-white/60 transition-all"
                  @click="onActionView(slot.data.service, slot.data.id)"
                >
                  {{ slot.data.title }}
                </div>
              </template>
            </Column>
            <Column header="Status">
              <template #body="slot">
                <Chip
                  @click="(event) => toggleActionStatus(event, slot.data.id)"
                  :disabled="slot.data.status == 'in_progress'"
                  aria-haspopup="true"
                  :aria-controls="`status-${slot.data.id}`"
                  :class="[
                    'p-2 text-white text-xs cursor-pointer',
                    slot.data.status == 'in_progress' ? 'bg-orange-600' : '',
                    slot.data.status == 'done' ? 'bg-green-600' : '',
                    slot.data.status == 'backlog'
                      ? 'bg-blue-600'
                      : 'bg-gray-500',
                  ]"
                  v-if="slot.data.status != null"
                >
                  <div class="mx-2 my-1 text-sm">
                    {{
                      capitalizeFirstLetterAndReplaceUnderscore(
                        slot.data.status
                      )
                    }}
                  </div>
                </Chip>
                <Menu
                  v-if="slot != null"
                  class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800"
                  :ref="
                    (el) => (actionStatusState[`status-${slot.data.id}`] = el)
                  "
                  :id="`status-${slot.data.id}`"
                  :model="actionStatusItems"
                  :popup="true"
                >
                  <template #start>
                    <div
                      class="text-white w-full h-full flex flex-col font-bold items-center pt-3 justify-center"
                    >
                      Select Project Status
                    </div>
                  </template>
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full gap-2 px-3 py-1 cursor-pointer"
                      @click="updateStatus(slot.data.id, item.value)"
                    >
                      <span>{{ item.label }}</span>
                    </div>
                  </template>
                </Menu>
              </template>
            </Column>
            <Column field="duration" header="Length"></Column>
            <Column header="Date Added">
              <template #body="slot">
                {{ trimStringAtT(slot.data.created_at) }}
              </template>
            </Column>
            <Column header="Last Edit">
              <template #body="slot">
                {{ trimStringAtT(slot.data.created_at) }}
              </template>
            </Column>
            <Column header="Progress">
              <template #body="slot">
                <ProgressBar
                  pt="{ value: bg-green-500 }"
                  :value="slot.data.progress"
                  style="height: 7px"
                  :showValue="false"
                  :mode="
                    slot.data.status == 'in_progress'
                      ? 'indeterminate'
                      : 'determinate'
                  "
                />
              </template>
            </Column>
            <Column header="Action">
              <template #body="slot">
                <Button
                  v-if="slot != null"
                  @click="(event) => toggleAction(event, slot.data.id)"
                  class="pi pi-ellipsis-v cursor-pointer rounded-lg bg-blue-600 disabled:bg-gray-700 flex items-center justify-center p-2 w-min"
                  aria-haspopup="true"
                  :disabled="slot.data.status == 'in_progress'"
                  :aria-controls="`menu-${slot.data.id}`"
                >
                </Button>
                <Menu
                  v-if="slot != null"
                  class="dark text-sm w-min bg-gray-900 !text-gray-100 border-gray-800"
                  :ref="(el) => (actionState[`menu-${slot.data.id}`] = el)"
                  :id="`menu-${slot.data.id}`"
                  :model="actionItems"
                  :popup="true"
                >
                  <template #item="{ item }">
                    <div
                      class="flex items-center w-full gap-2 px-3 py-1 cursor-pointer"
                      @click="item.onClick(slot.data.service, slot.data.id)"
                    >
                      <span :class="item.icon" />
                      <span>{{ item.label }}</span>
                    </div>
                  </template>
                </Menu>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <!-- pagination -->
      <!-- <Paginator @page="handlePageChange" :rows="10" :totalRecords="120"> -->
      <!-- </Paginator> -->
    </div>
    <!-- rename dialog -->
    <Dialog
      v-model:visible="renameDialogVisibel"
      modal
      header="Edit Project Name"
      :pt="{
        root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
        header: 'm-5',
        content: 'mx-5 flex items-center justify-center my-5',
        mask: '!bg-black/50   backdrop-blur-sm',
      }"
      dismissableMask
    >
      <div class="flex flex-col w-full h-full px-8">
        <InputText @change="updateTitle" v-model="newName" />
        <div class="w-full mt-3 px-10">
          <Buttonn
            @click="renameDialogVisibel = false"
            class="p-button-primary bg-blue-600 w-full"
          >
            Close
          </Buttonn>
        </div>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="exportDialogVisible"
      modal
      header="Export Project"
      :pt="{
        root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
        header: 'm-5',
        content: 'mx-5 flex flex-col items-center justify-center my-5',
        mask: '!bg-black/50 backdrop-blur-sm',
      }"
      dismissableMask
    >
      <div class="w-full mb-4">
        <!-- Dropdown for export formats -->
        <Dropdown
          v-model="selectedExportFormat"
          :options="exportFormats"
          optionLabel="label"
          optionValue="value"
          placeholder="Select format for export"
          class="w-full bg-gray-900"
        />
      </div>

      <Buttonn
        label="Export"
        class="p-button-primary bg-blue-600 w-full disabled:bg-gray-400 border-transparent"
        @click="exportProject"
        :disabled="!selectedExportFormat"
      />
    </Dialog>

    <!-- end rename dialog -->
  </DashboardBody>
</template>

<script setup>
import Menu from "primevue/menu";
import ProgressBar from "primevue/progressbar";
import DashboardBody from "@/components/DashboardBody.vue";
import Dropdown from "primevue/dropdown";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SearchBar from "@/components/SearchBar.vue";
import SelectButton from "primevue/selectbutton";
import { useToast } from "primevue/usetoast";
import { useProjectStore } from "@/stores/project";
// import Paginator from 'primevue/paginator';
import { RouterLink } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useWorkspaceStore } from "@/stores/workspace";
import Buttonn from "primevue/button";
import Chip from "primevue/chip";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import {
  trimStringAtT,
  capitalizeFirstLetterAndReplaceUnderscore,
  lowercaseAndReplaceSpaces,
} from "@/utils";
const toast = useToast();

import axios from "axios";
import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

const onActionTrash = async (_, id) => {
  try {
    await projectStore.deleteProject(id);
    await projectStore.fetchDeletedProjects(workspaceStore.id); // Refresh the list of projects
    await fetchData();
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Don't Have Access",
      detail: "You can't perform this action as Editor",
      life: 3000,
    });
  }
};
const updateStatus = async (project_id, value) => {
  try {
    await projectStore.updateStatus(project_id, value);
  } catch {
    toast.add({
      severity: "error",
      summary: "Don't Have Access",
      detail: "You can't perform this action as Editor",
      life: 3000,
    });
  }
  await fetchData();
};

const actionState = ref({});
const toggleAction = (event, id) => {
  const menuRef = `menu-${id}`;
  const menuInstance = actionState.value[menuRef];
  if (menuInstance && typeof menuInstance.toggle === "function") {
    menuInstance.toggle(event);
  } else {
    console.error(
      `Menu instance for ${menuRef} not found or toggle is not a function.`
    );
  }
};
const actionStatusItems = ref([
  {
    items: [
      {
        label: "Backlog",
        value: "backlog",
      },
      {
        label: "Started",
        value: "started",
      },
      {
        label: "Done",
        value: "done",
      },
    ],
  },
]);
const actionStatusState = ref({});
const toggleActionStatus = (event, id) => {
  const menuRef = `status-${id}`;
  const menuInstance = actionStatusState.value[menuRef];
  if (menuInstance && typeof menuInstance.toggle === "function") {
    menuInstance.toggle(event);
  } else {
    console.error(
      `Menu instance for ${menuRef} not found or toggle is not a function.`
    );
  }
};
const router = useRouter();
const onActionView = async (service, id) => {
  console.log(service, id);
  if (service == "caption") {
    router.push(`/caption/${id}`);
  }
  if (service == "transcription") {
    router.push(`/transcription/${id}`);
  }
};

const updateTitle = async () => {
  await projectStore.updateTitle(projectIdToRename.value, newName.value);
  await fetchData();
};
const projectIdToRename = ref();
const renameDialogVisibel = ref(false);
const projectIdToExport = ref();

const selectedExportFormat = ref(null);

const exportDialogVisible = ref(false);

const exportFormats = [
  { label: "Text Document (.txt)", value: "txt" },
  { label: "PDF Document (.pdf)", value: "pdf" },
  { label: "Excel (.csv)", value: "csv" },
  { label: "JSON (.json)", value: "json" },
];
const exportProject = async () => {
  if (!selectedExportFormat.value) {
    // Handle case when no format is selected
    console.error("Please select an export format.");
    return;
  }

  try {
    const exportUrl = `https://typeteam.gozar.team/api/export/${projectIdToExport.value}/${selectedExportFormat.value}/`;
    const response = await axios.get(exportUrl, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });
    const blob = response.data;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${projectIdToExport.value}.${selectedExportFormat.value}`; // Set the file name
    link.click(); // Trigger download
  } catch (error) {
    console.error("Failed to export project:", error);
  }
};

const newName = ref();
const onActionRename = async (_, id) => {
  await projectStore.fetch(id);
  renameDialogVisibel.value = true;
  projectIdToRename.value = id;
  newName.value = projectStore.projects.filter((obj) => obj.id == id)[0].title;
};
const onActionExport = (_, id) => {
  exportDialogVisible.value = true;
  projectIdToExport.value = id;
};

const actionItems = ref([
  {
    items: [
      {
        label: "View",
        icon: "pi pi-window-maximize",
        onClick: onActionView,
      },
      {
        label: "Export",
        icon: "pi pi-download",
        onClick: onActionExport,
      },
      // {
      //   label: "Share",
      //   icon: "pi pi-share-alt",
      //   onClick: onActionRename,
      // },
      {
        label: "Rename",
        icon: "pi pi-pencil",
        onClick: onActionRename,
      },
      // {
      //   label: "Transfer",
      //   icon: "pi pi-reply",
      //   onClick: onActionRename,
      // },
      {
        label: "Move To Trash",
        icon: "pi pi-trash",
        onClick: onActionTrash,
      },
    ],
  },
]);
var route = ref(useRoute());
const value = ref(route.value.query["filter"]);
const options = ref(["Backlog", "Started", "Done", "In Progress"]);

watch(
  () => route.value.query["filter"],
  () => {
    value.value = route.value.query["filter"];
    fetchData(lowercaseAndReplaceSpaces(value.value));
  }
);
watch(
  () => value.value,
  () => {
    console.log(value.value);
    if (value.value === null) {
      router.push("/admin/projects");
    }
  }
);
// const currentPage = ref(1);

// const handlePageChange = (event) => {
//   currentPage.value = event.page;
//   console.log(`Page changed to: ${event.page}`);
// };
const projectStore = useProjectStore();
const workspaceStore = useWorkspaceStore();
const fetchData = async (filter = null) => {
  await workspaceStore.fetchWorkspace();
  await projectStore.fetchProjects(workspaceStore.id, filter);
  if (projectStore.projects.some((obj) => obj.status == "in_progress")) {
    setTimeout(async () => {
      await fetchData(filter);
    }, 1000);
    return;
  }
};
onMounted(async () => {
  await fetchData();

  watch(
    () => workspaceStore.id,
    async () => {
      await fetchData();
    }
  );
});
</script>

<style>
:root {
  --p-menu-item-color: #6f767e;
  --p-paginator-nav-button-selected-background: #292929;
  --p-datatable-header-border-color: #292929;
  --p-datatable-header-cell-background: #00000000;
  --p-datatable-row-background: #00000000;
  --p-datatable-header-cell-color: #6f767e;
  --p-datatable-row-color: #f1f2f3;
  --p-datatable-border-color: #272b30;
}

.p-paginator {
  @apply bg-transparent text-gray-400 my-3;
}

.p-paginator-first {
  @apply hover:bg-transparent;
}

.p-paginator-last {
  @apply hover:bg-transparent;
}

.p-paginator .p-paginator-page {
  @apply hover:bg-gray-800/40 rounded-lg text-gray-400;
}

::v-deep .p-selectbutton {
  background-color: #00000000 !important;
}

.p-inputtext {
  @apply bg-transparent !px-3 !py-1 text-white;
}
</style>
