<template>
  <div class="p-11">


    <primeDialog
      header="Invite Users"
      v-model:visible="isVisible"
      class="custom-dialog bg-gray-800 p-30"
      :modal="true"
      @hide="emitClose"
      style="padding: 20px !important;"
      
    >
          <p class="description" style="margin-top:0 !important;">Add colleagues and collaborators to your workspace</p>

      <div>
        <div class="input-button-wrapper">
          <div class="input-wrapper">
            <div class="combined-input">
              <primeInputText
                id="email"
                v-model="inviteStore.email"
                placeholder="Email"
                class="input-textt"
              />
              <primeDropdown
                v-model="inviteStore.role"
                :options="roles"
                class="embedded-dropdown"
                placeholder="editor"
              />
            </div>
          </div>

          <primeButton label="Invite" class="invite-button" @click="inviteStore.sendInvite" />
        </div>

        <p class="description">Type or paste the email of the person you want to invite.</p>


      

        
      </div>
    </primeDialog>
  </div>
</template>


<script>
import { useInviteStore } from '@/stores/invite'; // Adjust path as needed

import '@/styles/login.css';
import { computed } from 'vue';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
    data() {
    return {
      role: "editor",
      roles: ["editor", "admin"],
      

    };
  },
  setup(props, { emit }) {
    const inviteStore = useInviteStore();
    const isVisible = computed({
      get: () => props.visible,
      set: (val) => emit('update:visible', val),
    });

    function emitClose() {
      emit('update:visible', false);
      inviteStore.resetInvite();
    }

  

    return { isVisible, inviteStore, emitClose };
  },
};
</script>

<style>
.p-field {
  margin-bottom: 1.5rem;
}

.pulse {
  animation: pulse-animation 0.4s ease-in-out;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
