<template>
  <LoadingOverlay v-if="isLoading" small />
  <div
    class="flex flex-col items-center justify-between w-full h-full animate-duration-700"
  >
    <!-- saving and errors -->
    <div
      class="w-full fixed z-[100] top-6 text-white animate-fade"
      v-if="savingState == 'saving'"
    >
      <ProgressSpinner style="width: 20px; height: 20px" /> saving ...
    </div>
    <div
      class="w-full fixed z-[100] top-6 text-red-500 animate-fade"
      v-if="savingState == 'network'"
    >
      network error
    </div>
    <!-- header -->
    <EditorHeader />
    <!-- main editor -->
    <div class="flex w-full text-white h-full">
      <!-- left -->
      <div class="w-3/5 p-10">
        <div class="flex items-center justify-between w-full">
          <div class="text-4xl font-bold">Subtitles</div>
          <div class="flex gap-3 font-bold">
            <div
              class="flex gap-1 text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
            >
              <EditPencil /> Styles
            </div>
            <div
              class="flex gap-1 text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
              @click="translateDialogVisible = true"
            >
              <Globe /> Transilate
            </div>
          </div>
        </div>
        <!-- captions -->
        <div class="w-full h-[45vh]">
          <ScrollPanel class="w-full h-full">
            <div
              ref="scrollContent"
              class="w-full flex flex-col items-center text-gray-300"
            >
              <div
                v-for="(item, index) in captions"
                :ref="(el) => (captionRefs[index] = el)"
                :key="index"
                class="flex text-gray-300 my-3 w-full"
              >
                <div class="w-full">
                  <Textarea
                    :draggable="false"
                    :auto-resize="true"
                    :dir="projectStore.language == 'Persian' ? 'rtl' : 'ltr'"
                    placeholder="write subtitle here ..."
                    :class="[
                      'border-4 rounded-none border-r-transparent border-b-transparent border-t-transparent w-full hover:animate-pulse bg-transparent text-gray-300',
                      index == currentPlayingIndex
                        ? 'border-l-blue-600 '
                        : 'border-l-transparent',
                    ]"
                    :value="item.text"
                    v-model="item.text"
                    @change="
                      onCaptionChange(item);
                      loadTimelineMetadata();
                    "
                    @click="skipToTimestamp(item.start_timestamp)"
                  />
                </div>
                <div class="w-fit flex flex-col mx-2">
                  <div class="flex items-center justify-start">
                    <span class="w-5 text-xs">In: </span>
                    <InputMask
                      class="border-none hover:animate-pulse text-sm w-28"
                      :value="item.start_timestamp"
                      v-model="item.start_timestamp"
                      mask="99:99:99.99"
                      placeholder="00:00:00.00"
                      @change="
                        onCaptionChange(item);
                        loadTimelineMetadata();
                      "
                    />
                  </div>
                  <div class="flex items-center justify-start">
                    <span class="w-5 text-xs">Out: </span>
                    <InputMask
                      class="border-none hover:animate-pulse text-sm w-28"
                      :value="item.end_timestamp"
                      v-model="item.end_timestamp"
                      mask="99:99:99.99"
                      placeholder="00:00:00.00"
                      @change="
                        onCaptionChange(item);
                        loadTimelineMetadata();
                      "
                    />
                  </div>
                </div>
                <div class="flex flex-col items-center">
                  <div
                    class="p-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 cursor-pointer"
                  >
                    <ChatBubble
                      v-tooltip.bottom="'add comment'"
                      @click="
                        commentDialog.visible = true;
                        commentDialog.caption = item.id;
                        commentDialog.text = null;
                      "
                    />
                  </div>
                  <div
                    class="p-2 w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600 cursor-pointer"
                    v-tooltip.bottom="'delete subtitle'"
                    @click="
                      editorStore.deleteCaption(item);
                      fetchData();
                    "
                  >
                    <Trash />
                  </div>
                </div>
              </div>
              <!-- end captions -->
            </div>
          </ScrollPanel>
        </div>
        <Divider align="center" type="solid" class="my-10 transition">
          <div
            @click="
              addNewCaption()
              // scrollToCaption(captions.length);
            "
            class="bg-gray-800 px-4 rounded-2xl cursor-pointer text-gray-300/70 hover:bg-gray-700 transition"
          >
            Add New Subtitle Line
          </div>
        </Divider>
      </div>
      <!-- end left -->
      <!-- right -->
      <div class="w-2/5 bg-gray-800">
        <div>
          <!-- header -->
          <div class="w-full flex justify-between items-center p-10">
            <div class="flex w-min">
              <InputText
                :ref="titleInputField"
                @input="updateWidth"
                @change="updateTitle"
                :style="{ width: inputWidth }"
                class="hover:text-gray-300/60 hover:animate-pulse text-2xl w-44 max-w-80 overflow-hidden bg-transparent border-none text-gray-300 font-bold"
                v-model="title"
                :value="title"
              />
              <div
                @click="focusInput"
                class="flex h-min p-2 cursor-pointer rounded-lg text-gray-400 text-sm bg-gray-800 hover:bg-gray-700 transition hover:shadow-gray-400/10 shadow-xl shadow-transparent items-center justify-center gap-1 font-bold"
              >
                <EditPencil /> <span>Rename</span>
              </div>
            </div>
            <div
              class="flex text-gray-300 text-sm gap-2 items-center justify-center"
            >
              <Globe />
              {{ projectStore.language }}
            </div>
          </div>
          <!-- end header -->
          <!-- video -->
          <div class="mt-10 relative p-5">
            <video
              :src="projectStore.file"
              class="w-full h-80 rounded-lg bg-black"
              ref="video"
            />

            <div
              class="absolute bottom-10 inset-x-0 box-border flex items-center justify-center"
              v-if="
                currentPlayingIndex != undefined &&
                currentPlayingIndex != null &&
                captions[currentPlayingIndex] != undefined
              "
            >
              <div
                v-if="
                  durationToSecs(
                    captions[currentPlayingIndex].start_timestamp
                  ) < video.currentTime &&
                  durationToSecs(captions[currentPlayingIndex].end_timestamp) >
                    video.currentTime
                "
                class="p-2 bg-black/50 max-w-80 w-fit text-center rounded-md text-sm font-bold"
              >
                {{ captions[currentPlayingIndex].text }}
              </div>
            </div>
          </div>
          <!-- end video -->
        </div>
      </div>
      <!-- end right -->
    </div>
    <!-- end main editor -->

    <!-- player -->

    <div class="flex flex-col w-full bg-gray-800 border-t border-gray-300/50">
      <Slider
        v-model="videoProgress"
        :model-value="videoProgress"
        :min="0"
        :max="100"
        step="0.001"
        class="mb-6"
        :pt="{ handle: 'hidden', range: 'bg-blue-600' }"
        @change="onSeekerChange"
      />
      <!-- player top -->
      <div class="justify-between items-center flex w-full">
        <div class="w-1/3 flex items-center justify-center gap-2">
          <div
            class="text-white py-1 px-5 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
          >
            {{ isTrackTimeCurrent }}
            /
            {{ isTrackTimeTotal }}
          </div>
          <!-- <div -->
          <!--   class="flex text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition" -->
          <!-- > -->
          <!--   <Scissor /> split -->
          <!-- </div> -->
          <div
            class="text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
            @click="triggerUndo"
          >
            <Undo />
          </div>
          <div
            class="text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
            @click="triggerRedo"
          >
            <Redo />
          </div>
        </div>
        <!-- middle -->
        <div class="flex items-center justify-center w-1/3">
          <div class="flex gap-3 items-center">
            <div
              class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
              @click="backward()"
            >
              <Backward15Seconds />
            </div>
            <div
              class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
              @click="playFunc()"
            >
              <Play v-if="!isPlaying" class="transition" />
              <Pause v-else class="transition" />
            </div>
            <div
              class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
              @click="forward()"
            >
              <Forward15Seconds />
            </div>
          </div>
        </div>
        <!-- end middle -->
        <div class="w-1/3 flex justify-end items-center">
          <div
            class="text-white flex justify-center items-center my-4 w-96 gap-3"
          >
            <ZoomOut />
            <Slider
              v-model="zoomLevel"
              :min="1"
              :max="5"
              step="0.1"
              class="w-1/2"
            />
            <ZoomIn />
          </div>

          <div
            class="text-white py-1 px-2 mr-1 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
            @click="findAndReplaceDialogVisible = true"
          >
            <Search />
          </div>
          <div
            class="text-white py-1 px-2 rounded-lg border-gray-400 hover:bg-gray-800 border cursor-pointer transition"
            @click="toggleSettingsSidebar"
          >
            <Settings />
          </div>
          <shortcutsSidebar
            v-if="isSettingsSidebarOpen"
            @close="toggleSettingsSidebar"
          />
        </div>
      </div>

      <!-- end player top -->
      <div class="w-[100vw] overflow-x-scroll">
        <div
          id="timeline-container"
          :style="{ width: dynamicWidth }"
          class="flex flex-col"
        >
          <div class="w-full -mb-9">
            <Timeline
              :value="timelinePoints"
              :pt="{ eventMarker: '' }"
              layout="horizontal"
              align="bottom"
            >
              <template #content="slotProps">
                <div
                  class="w-full flex items-start text-gray-300 text-xs -mb-3"
                >
                  {{ `${slotProps.item}s` }}
                </div>
              </template>
            </Timeline>
          </div>
          <div class="w-full flex bg-dot bg-repeat bg-contain">
            <GridLayout
              v-model:layout="gridLayoutValues"
              :row-height="40"
              :responsive="false"
              :vertical-compact="true"
              :col-num="numOfCols"
              :max-rows="1"
              class="w-full"
              prevent-collision
              is-bounded
              @layout-updated="onLayoutUpdated"
            >
              <template #item="{ item }">
                <div
                  v-if="item.i == '0'"
                  class="flex bg-gray-900/50 rounded-md border items-center justify-start gap-2 text-sm text-white"
                >
                  <video :src="projectStore.file" class="h-9 ml-4 my-1" />
                  {{ projectStore.title }}
                </div>
                <div
                  v-else
                  :class="[
                    'overflow-hidden text-white flex transition-all h-full border rounded-md bg-[#8584E1] items-center justify-between',
                    ,
                    item.i == (currentPlayingIndex + 1).toString()
                      ? 'shadow border-green-400 border-2'
                      : '',
                  ]"
                  @click="skipToSecs(item.x / 1000)"
                >
                  <div class="w-24 text-xs">
                    {{ secsToDuration(parseFloat(item.x) / 1000) }}
                  </div>
                  <div
                    class="w-full h-min overflow-hidden whitespace-nowrap text-ellipsis px-1"
                  >
                    {{ captions[parseInt(item.i) - 1].text }}
                  </div>
                  <div class="w-24 text-xs">
                    {{
                      secsToDuration(
                        (parseFloat(item.x) + parseFloat(item.w)) / 1000
                      )
                    }}
                  </div>
                </div>
              </template>
            </GridLayout>
          </div>
        </div>
      </div>
    </div>
    <!-- end player -->
  </div>

  <Dialog
    modal
    header="Add new Comment"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    v-model:visible="commentDialog.visible"
  >
    <div class="w-full items-center flex gap-2 justify-start text-sm">
      <img
        :src="authStore.avatar || require('@/assets/profilepic.jpg')"
        alt="pic"
        class="w-8 h-8 rounded-full cursor-pointer"
      />
      {{ authStore.name }}
    </div>
    <Textarea
      v-model="commentDialog.text"
      placeholder="Enter comment here ..."
      :class="[
        '!relative w-full !px-10 !py-6 !my-3 overflow-hidden bg-transparent !hover:text-gray-300/60 text-gray-300/60 font-bold !transition-all !duration-1000',
      ]"
    />
    <div>
      <Button
        label="submit"
        class="bg-blue-600 hover:bg-blue-500 !px-4 !py-2 disabled:bg-gray-400"
        :disabled="commentDialog.caption == null || commentDialog.text == null"
        @click="
          createComment();
          commentDialog.visible = false;
        "
      />
    </div>
  </Dialog>
  <!-- translateion dialog -->
  <Dialog
    modal
    header="Translate Project"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    v-model:visible="translateDialogVisible"
  >
    <Select
      v-model="translateSelectedLanguage"
      class="mb-5"
      :options="langs"
      :pt="{
        dropdown: 'bg-transparent',
        label: 'bg-transparent text-gray-300',
        listContainer: ' !text-gray-300',
        list: '!text-gray-300',
        overlay: 'bg-gray-900',
        root: 'bg-transparent',
        optionLabel: 'text-gray-300',
        option: 'hover:bg-gray-700 ',
      }"
      optionLabel="name"
      placeholder="Select a Language"
    />
    <Button
      label="submit"
      class="bg-blue-600 hover:bg-blue-500 !px-4 !py-2 disabled:bg-gray-400"
      @click="translateProject"
      :disabled="translateSelectedLanguage == null"
    />
  </Dialog>

  <!-- find and replace -->
  <Dialog
    modal
    header="Find and Replace"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    v-model:visible="findAndReplaceDialogVisible"
  >
    <div class="w-full flex items-center gap-4 p-3 box-border">
      <FloatLabel variant="on">
        <InputText id="word" v-model="findAndReplaceState.word" />
        <label for="word">Word</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="updated_word"
          v-model="findAndReplaceState.updated_word"
        />
        <label for="updated_word">Updated Word</label>
      </FloatLabel>
    </div>
    <Button
      label="submit"
      class="bg-blue-600 hover:bg-blue-500 !px-4 !py-2 disabled:bg-gray-400"
      @click="findAndReplaceProject"
      :disabled="
        findAndReplaceState.word == null ||
        findAndReplaceState.updated_word == null
      "
    />
  </Dialog>
</template>

<script setup>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import EditorHeader from "@/components/EditorHeader.vue";
import ProgressSpinner from "primevue/progressspinner";
import Divider from "primevue/divider";
import Select from "primevue/select";
import { ref, onMounted, watch, computed } from "vue";
import { useProjectStore } from "@/stores/project";
import { useEditorStore } from "@/stores/editor";
import { useRoute, useRouter } from "vue-router";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import { Globe, EditPencil, Trash, ChatBubble, Search } from "@iconoir/vue";
import { nextTick } from "vue";
import { GridLayout } from "grid-layout-plus";
import { Timeline } from "primevue";
import { Slider } from "primevue";
import { useKeyboardShortcuts } from "@/composables/useKeyboardShortcuts";
import ShortcutsSidebar from "@/components/shortcutsSidebar.vue";

import {
  // MacOptionKey,
  // ClosedCaptionsTag,
  Pause,
  Play,
  // Search,
  Forward15Seconds,
  Backward15Seconds,
  Undo,
  Redo,
  // Scissor,
  Settings,
  ZoomIn,
  ZoomOut,
  // Translate,
} from "@iconoir/vue";
import { durationToSecs, secsToDuration } from "@/utils";
import Textarea from "primevue/textarea";
import ScrollPanel from "primevue/scrollpanel";
import InputMask from "primevue/inputmask";
import { useShortcutStore } from "@/stores/shortcut";
import { useAuthStore } from "@/stores/auth";
import Dialog from "primevue/dialog";
import Button from "primevue/button";

const authStore = useAuthStore();
const isLoading = ref(false);
const projectStore = useProjectStore();
const editorStore = useEditorStore();
const shortcutStore = useShortcutStore();
const commentDialog = ref({
  visible: false,
  caption: null,
  text: null,
});
function triggerUndo() {
  document.execCommand("undo");
  // const undoEvent = new KeyboardEvent("keydown", {
  //   key: "z",
  //   ctrlKey: true,
  //   bubbles: true,
  // });
  // document.dispatchEvent(undoEvent);
}

function triggerRedo() {
  document.execCommand("undo");
  // const redoEvent = new KeyboardEvent("keydown", {
  //   key: "y",
  //   ctrlKey: true,
  //   bubbles: true,
  // });
  // document.dispatchEvent(redoEvent);
}
const createComment = async () => {
  await editorStore.createComment(
    commentDialog.value.caption,
    commentDialog.value.text
  );
  await fetchData();
};
let isSettingsSidebarOpen = ref(false);

const toggleSettingsSidebar = () => {
  isSettingsSidebarOpen.value = !isSettingsSidebarOpen.value;
};
const translateSelectedLanguage = ref(null);
const translateDialogVisible = ref(false);
const langs = ref([
  { name: "English", code: "EN" },
  { name: "Spanish", code: "SP" },
  { name: "Persian", code: "FA" },
]);
const translateProject = async () => {
  await projectStore.translateProject(id, translateSelectedLanguage.value.name);
  router.push(`/admin/projects`);
};

const findAndReplaceDialogVisible = ref(false);
const findAndReplaceState = ref({
  word: null,
  updated_word: null,
});
const findAndReplaceProject = async () => {
  await projectStore.findAndReplace(
    id,
    findAndReplaceState.value.word,
    findAndReplaceState.value.updated_word
  );
  findAndReplaceDialogVisible.value = false;
  await fetchData();
};
const route = useRoute();
const router = useRouter();
const id = route.params.id;
const shortcutActions = {
  undo: () => {
    triggerUndo();
  },
  redo: () => {
    triggerRedo();
  },
  play_pause: (event) => {
    if (isInputFocused()) return;
    event.preventDefault();
    playFunc();
    // Add your tab key logic here
  },
  rewind: () => {
    if (isInputFocused()) return;
    backward();
  },
  skip_forward: () => {
    if (isInputFocused()) return;
    forward();
  },
  find: () => {
    findAndReplaceDialogVisible.value = true;
  },
  mark_paragraph_completed: () => {},
  select_next_speaker: () => {},
  open_speaker_selector: () => {},
  toggle_fullscreen: () => {},
};
const isInputFocused = () => {
  const activeElement = document.activeElement;
  return (
    activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA"
  );
};
// {
//     "Ctrl+s": () => {
//       if (isInputFocused()) return;
//       savingState.value = "saving";
//       console.log("Save shortcut triggered");
//       setTimeout(() => {
//         savingState.value = "";
//       }, 1000);
//       // Add your save logic here
//     },
//     "Ctrl+z": () => {
//       if (isInputFocused()) return;
//       console.log("Undo shortcut triggered");
//       // Add your undo logic here
//     },
//     Space: (event) => {
//       if (isInputFocused()) return;
//       console.log("Space key pressed");
//       event.preventDefault();
//       playFunc();
//     },
//     Tab: (event) => {
//       if (isInputFocused()) return;
//       console.log("Tab key pressed");
//       event.preventDefault();
//       playFunc();
//       // Add your tab key logic here
//     },
//     ArrowLeft: () => {
//       if (isInputFocused()) return;
//       console.log("Left arrow key pressed");
//       backward();
//     },
//     ArrowRight: () => {
//       if (isInputFocused()) return;
//       console.log("Right arrow key pressed");
//       forward();
//     },
//     // Add more shortcuts as needed
//   }
onMounted(async () => {
  await fetchData();
  var shortcuts = {};
  for (const action in shortcutStore.shortcuts) {
    shortcuts[shortcutStore.shortcuts[action]] = shortcutActions[action];
  }
  useKeyboardShortcuts(shortcuts);
});
const speakers = ref([]);
const currentSpeaker = ref();
const captions = ref([]);
const captionRefs = ref([]);
const currentPlayingIndex = ref();
const scrollContent = ref(null);
const savingState = ref();
const title = ref();

const fetchData = async () => {
  isLoading.value = true;
  await shortcutStore.fetchShortcuts();
  await projectStore.fetch(id);

  if (projectStore.status == "in_progress") {
    setTimeout(async () => {
      await fetchData();
    }, 1000);
    return;
  }
  await editorStore.fetch(id);
  title.value = projectStore.title;
  if (editorStore.speakers.length > 0) {
    speakers.value = editorStore.speakers;
    currentSpeaker.value = speakers.value[0];
    captions.value = editorStore.captions;
  } else {
    await editorStore.createSpeaker(id, "Salar Aqili");
    await editorStore.fetch(id);
    speakers.value = editorStore.speakers;
    currentSpeaker.value = speakers.value[0];
    captions.value = editorStore.captions;
  }

  loadTimelineMetadata();

  const length = title.value.length;
  inputWidth.value = `${Math.max(length + 1, 5)}ch`;
  isLoading.value = false;
};
const inputWidth = ref(1);
const titleInputField = ref();
const focusInput = async () => {
  await nextTick();
  if (titleInputField.value) {
    titleInputField.value.focus();
  } else {
    console.error("Input field is not available");
  }
};
const updateWidth = () => {
  const length = title.value.length;
  inputWidth.value = `${Math.max(length + 1, 5)}ch`;
};
const updateTitle = async () => {
  savingState.value = "saving";
  try {
    await projectStore.updateTitle(id, title.value);

    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }
};

const onCaptionChange = async (caption) => {
  savingState.value = "saving";
  try {
    await editorStore.updateCaption(caption);

    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }

  loadTimelineMetadata();
};
const isPlaying = ref(false);
const video = ref();
const isTrackTimeCurrent = ref("00:00:00");
const isTrackTimeTotal = ref("00:00:00");
const backward = () => {
  if (video.value.currentTime < 15) {
    video.value.currentTime = 0;
  } else {
    video.value.currentTime -= 15;
  }
};
const forward = () => {
  if (video.value.duration - video.value.currentTime > 15) {
    video.value.currentTime += 15;
  }
};
const playFunc = () => {
  if (isPlaying.value == true) {
    video.value.pause();
    isPlaying.value = false;
  } else {
    video.value.play();
    isPlaying.value = true;
  }
};
const videoProgress = ref(0);
const timeupdate = () => {
  video.value.addEventListener("timeupdate", function () {
    scrollToTime(video.value.currentTime);
    isTrackTimeCurrent.value = secsToDuration(video.value.currentTime);
    videoProgress.value =
      (video.value.currentTime / video.value.duration) * 100;
  });
};

const loadmetadata = () => {
  video.value.addEventListener("loadedmetadata", function () {
    isTrackTimeTotal.value = secsToDuration(video.value.currentTime);
  });
};

const onSeekerChange = (value) => {
  video.value.currentTime = value * (video.value.duration / 100);
};
const addNewCaption = async () => {
  savingState.value = "saving";
  video.value.pause();
  isPlaying.value = false;
  const lastcaption = captions.value[captions.value.length - 1];
  try {
    if (lastcaption == undefined) {
      editorStore.createCaption(
        id,
        currentSpeaker.value.id,
        "Edit This ...",
        0,
        2
      );
    } else {
      editorStore.createCaption(
        id,
        currentSpeaker.value.id,
        "Edit This ...",
        lastcaption.end_timestamp,
        secsToDuration(durationToSecs(lastcaption.end_timestamp) + 2)
      );
    }
    savingState.value = "";
  } catch (e) {
    savingState.value = "network";
  }
  await fetchData();
};
const skipToTimestamp = (timestamp) => {
  video.value.currentTime = durationToSecs(timestamp) + 0.0002;
};
const skipToSecs = (secs) => {
  video.value.currentTime = secs + 0.0002;
};
const scrollToCaption = (index) => {
  currentPlayingIndex.value = index;
  // const captionElement = captionRefs.value[index];
  // if (captionElement && scrollContent.value) {
  //   scrollContent.value.parentElement.scrollTop = captionElement.offsetTop;
  // }
};
const scrollToTime = (secs) => {
  const cap = captions.value.findIndex((c) => {
    const start = durationToSecs(c.start_timestamp);
    const end = durationToSecs(c.end_timestamp);
    return secs >= start && secs <= end;
  });
  scrollToCaption(cap);
};
//
watch(
  () => video.value,
  () => {
    timeupdate();
    loadmetadata();
  }
);

// timeline
const timelinePoints = ref([]);
const gridLayoutValues = ref([]);
const numOfCols = ref();
const zoomLevel = ref(1);

const dynamicWidth = computed(() => {
  // const screenWidth = window.innerWidth;
  return `${100 + 100 * (zoomLevel.value - 1) * 3}vw`;
});

const onLayoutUpdated = async (newLayout) => {
  // Skip if it's the video track (i === "0")

  for (var i = 1; i < newLayout.length; i++) {
    const changedItem = newLayout[i];
    const captionIndex = parseInt(changedItem.i) - 1;
    const caption = captions.value[captionIndex];

    // Convert grid positions back to timestamps
    const newStartTime = secsToDuration(changedItem.x / 1000);
    const newEndTime = secsToDuration((changedItem.x + changedItem.w) / 1000);

    if (
      caption.start_timestamp == newStartTime &&
      caption.end_timestamp == newEndTime
    ) {
      continue;
    }
    // Update caption with new times
    caption.start_timestamp = newStartTime;
    caption.end_timestamp = newEndTime;

    // Save changes via API
    await onCaptionChange(caption);
  }
};

const loadTimelineMetadata = () => {
  const step = Math.floor(video.value.duration / 10);
  timelinePoints.value = [];
  for (
    let index = 0;
    index <= video.value.duration + step + step;
    index = index + step
  ) {
    timelinePoints.value.push(index);
  }
  timelinePoints.value.pop();
  timelinePoints.value.push(
    timelinePoints.value[timelinePoints.value.length - 1] + step
  );

  numOfCols.value =
    timelinePoints.value[timelinePoints.value.length - 1] * 1000;

  gridLayoutValues.value = [];
  gridLayoutValues.value.push({
    x: 0,
    y: 1,
    w: timelinePoints.value[timelinePoints.value.length - 1] * 1000,
    h: 1,
    i: "0",
    static: true,
  });
  for (let index = 0; index < captions.value.length; index++) {
    const element = captions.value[index];
    const start = durationToSecs(element.start_timestamp);
    const width =
      durationToSecs(element.end_timestamp) -
      durationToSecs(element.start_timestamp);
    gridLayoutValues.value.push({
      x: start * 1000,
      y: 0,
      w: width * 1000,
      h: 1,
      i: `${index + 1}`,
      static: false,
    });
  }
  captions.value;
};
</script>

<style lang="scss">
.p-timeline {
  --p-timeline-event-marker-content-background: #111111;
}
</style>
