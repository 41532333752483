import { onUnmounted } from "vue";

export function useKeyboardShortcuts(shortcuts) {
  const handleKeydown = (event) => {
    const keyCombination = `${event.ctrlKey ? "Ctrl+" : ""}${
      event.shiftKey ? "Shift+" : ""
    }${event.altKey ? "Alt+" : ""}${event.key}`;
    if (shortcuts[keyCombination]) {
      shortcuts[keyCombination](event);
    }
  };
  window.addEventListener("keydown", handleKeydown);
  onUnmounted(() => {
    window.removeEventListener("keydown", handleKeydown);
  });
}
