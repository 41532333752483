<template>
  <div>
    <OverlayPanel
      ref="overlay"
      :style="overlayStyles"
      class="overlay-with-arrow"
    >
      <div class="notification-container">
        <div class="notification-header">
          <h3>Notification</h3>
          <i class="pi pi-ellipsis-h"></i>
        </div>

        <div>
          <div
            v-for="(notification) in notifications"
            :key="notification.id"
            class="notification-item"
            @click="markNotificationAsRead(notification.id)"
          >
            <div class="notification-details">
              <p><strong>{{ notification.message }}</strong></p>
              <p><span style="color: #6f767e">{{ notification.created_at }}</span></p>
            </div>
            <i v-if="!notification.is_read" class="pi pi-circle-fill blue-dot"></i>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from "primevue/overlaypanel";
import axios from "axios";

export default {
  components: {
    OverlayPanel,
  },
  data() {
    return {
      notifications: [],
      overlayStyles: {
        width: "422px",
        height: "400px",
        padding: "0",
        borderRadius: "16px",
        backgroundColor: "#111315",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      },
    };
  },
  computed: {
  hasUnreadNotifications() {
    console.log( this.notifications.some((notification) => !notification.is_read));

    return this.notifications.some((notification) => !notification.is_read);
  },
},

  methods: {
  async fetchNotifications() {
    try {
      const response = await axios.get("/notifications/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      this.notifications = response.data.map((notification) => ({
        ...notification,
      }));
      this.$emit("updateUnreadStatus", this.hasUnreadNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }
  ,
    async markNotificationAsRead(id) {
      try {
        await axios.post(`/notifications/${id}/read/`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const notification = this.notifications.find((n) => n.id === id);
        if (notification) notification.is_read = true;
        
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    },
    toggleDropdown(event) {
      this.$refs.overlay && this.$refs.overlay.toggle(event);
    },
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style scoped>
.notification-container {
  padding: 12px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 300px;
  overflow-y: auto;
  position: relative;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  z-index: 1;
  padding-top: 10px;
}

.notification-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-details {
  flex-grow: 1;
}

.time {
  font-size: 12px;
  color: #6f767e;
}
</style>
