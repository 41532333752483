import axios from 'axios';
import { defineStore } from 'pinia';

export const useShortcutStore = defineStore('shortcut', {
  state: () => ({
    shortcuts: {
      undo: '',
      redo: '',
      play_pause: '',
      rewind: '',
      skip_forward: '',
      find: '',
      mark_paragraph_completed: '',
      select_next_speaker: '',
      open_speaker_selector: '',
      toggle_fullscreen: '',
    },
    errorMessage: null,
  }),
  actions: {

    async fetchShortcuts() {
      try {
        const response = await axios.get('/editor/shortcuts/', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }); 
        this.shortcuts = response.data;
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage = error.response?.data?.detail || 'Failed to fetch shortcuts.';
        console.error(this.errorMessage);
      }
    },

    async updateShortcut(updatedData) {
      try {
        const response = await axios.put('/editor/shortcuts/', updatedData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }); 
        this.shortcuts = response.data;
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage = error.response?.data || 'Failed to update shortcut.';
        console.error(this.errorMessage);
      }
    },
  },
});
