<template>
  <div class="page-container">
    <div class="content-container">
      <div class="logo">
        <h1><img style="vertical-align: middle;" src="@/assets/logo.svg" alt="typeteam logo" class="icon" />typeteam</h1>
        <h2 style='color:#FFFFFF;font-size:20px' class="mt-3">Congrats!</h2>
        <p style="color:#8D8D8D;" class="account-text">You've been added to the workspace you were invited to. Please sign in to view the details. <a href="/sign-in" >Sign in</a></p>
      </div>
    </div>
  </div>
</template>

<script>

import '@/styles/login.css'; 
export default {
  name: "InvitationSuccess",
};
</script>
