import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "./auth";
import { useWorkspaceStore } from "./workspace";

export const useSearchStore = defineStore("search", {
  state: () => {
    return {
      is_loading: false,
      results: null,
    };
  },
  actions: {
    async search(query) {
      const authStore = useAuthStore();
      const workspaceStore = useWorkspaceStore();
      this.is_loading = true;
      const response = await axios.get(
        `/search/?query=${query.query}&workspace=${workspaceStore.id}`,
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      this.results = [
        {
          label: "Projects",
          items: response.data.projects,
        },
        {
          label: "Files",
          items: response.data.files,
        },
      ];
    },
  },
});
