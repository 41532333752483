<template>
<Toast />
  <div class="page-container">
    <div class="content-container" >
      <div class="logo"  >
        <h1>
          <img
            style="vertical-align: middle;"
            src="@/assets/logo.svg"
            alt="typeteam logo"
            class="icon"
          />
          typeteam
        </h1>
        <h2 style="color: #ffffff; font-size: 20px">Reset Password</h2>
        <p style="color: #8d8d8d">
          Enter the email address you registered with and we'll send you
          instructions to reset your password.
        </p>
      </div>
      <div class="form">
        <label for="email" class="label">Email</label>
        <input
          type="email"
          v-model="authStore.email"
          placeholder="Your Email"
          class="input-field"
        />
      </div>

      <button @click="sendResetInstructions" class="sign-in-button">
        Submit
      </button>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from '@/stores/auth';
import '@/styles/login.css';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router'; 




const authStore = useAuthStore();
const toast = useToast();
const router = useRouter();

const sendResetInstructions = async () => {
  try {
    await authStore.passwordResetRequest(); // Make the request
    toast.add({ severity: 'success', summary: 'Email Sent', detail: 'Instructions have been sent to your email.', life: 3000 });
    router.push('/reset-password-two'); // Redirect only if the request is successful

    
  } catch (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: "No user with this email address exists" || 'Something went wrong.', life: 3000 });
  }
};
</script>
