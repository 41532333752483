// invite.js
import { defineStore } from "pinia";
import { useWorkspaceStore } from "./workspace"; // Adjust the import path as needed
import axios from "axios";
import { useAuthStore } from "./auth";

export const useInviteStore = defineStore("invite", {
  state: () => ({
    email: "",
    role: "editor",
    roles: ["Editor", "Admin"],
    memberLink: "",
    invitationStatus: null,
  }),
  actions: {
    async sendInvite() {
      const workspaceStore = useWorkspaceStore(); // Get the workspace store
      if (!workspaceStore.id) {
        workspaceStore.fetchWorkspace(); // Fetch workspace data if not already loaded
      }
      if (!workspaceStore.id) {
        console.error("Workspace ID is required to send an invite.");
        this.invitationStatus = "error";
        return;
      }

      try {
        const response = await axios.post(
          `/collaborator/${workspaceStore.id}/invite/`,
          {
            email: this.email,
            permission: this.role,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        this.memberLink = response.data.invite_link;
        this.invitationStatus = "success";
      } catch (error) {
        this.invitationStatus = "error";
        console.error("Failed to send invite:", error);
      }
    },
    async acceptInvite(token) {
      const authStore = useAuthStore();
      try {
        await axios.get(`/collaborator/${token}/accept-invite/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        this.invitationStatus = "success";
      } catch (error) {
        this.invitationStatus = "error";
        console.error("Failed to send invite:", error);
      }
    },
    resetInvite() {
      this.email = "";
      this.role = null;
      this.memberLink = "";
      this.invitationStatus = null;
    },
  },
});
