<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 z-40" v-if="isOpen">
    <div class="absolute right-0 top-0 w-1/3 h-full bg-gray-900 text-white p-6 overflow-y-auto shadow-lg">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-xl font-bold">Key-Binding Settings</h2>
        <button class="text-gray-400 hover:text-white" @click="closeSidebar">✕</button>
      </div>
      <div class="flex justify-between items-center mb-4">
        <button
          class="px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded"
          @click="resetToDefaults"
        >
          Reset to defaults
        </button>
        <button
          class="px-4 py-2 bg-blue-600 hover:bg-blue-500 rounded"
          @click="saveSettings"
        >
          Save Settings
        </button>
      </div>
      <div>
        <table class="w-full text-left border-separate border-spacing-y-2">
          <thead>
            <tr>
              <th class="py-2">Action</th>
              <th class="py-2">Default</th>
              <th class="py-2">Customize</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(binding, index) in keyBindings" :key="index" class="text-sm">
              <td class="py-2">{{ binding.action }}</td>
              <td class="py-2">{{ binding.default }}</td>
              <td class="py-2">
                <input
                  type="text"
                  v-model="binding.custom"
                  placeholder="Customize"
                  class="w-full px-3 py-1 bg-gray-800 text-white rounded border border-gray-600 focus:ring-2 focus:ring-blue-600"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useShortcutStore } from '@/stores/shortcut';
import { mapActions, mapState } from 'pinia';

export default {
  data() {
    return {
      isOpen: true,
      keyBindings: [],
    };
  },
  computed: {
    ...mapState(useShortcutStore, ['shortcuts']),
  },
  methods: {
    ...mapActions(useShortcutStore, ['fetchShortcuts', 'updateShortcut']),
    async closeSidebar() {
      this.$emit('close');
    },
    async resetToDefaults() {
      this.keyBindings.forEach((binding) => {
        binding.custom = binding.default;
      });
    },
async saveSettings() {
  try {
    const updatedData = this.keyBindings.reduce((acc, binding) => {
      acc[binding.action.toLowerCase().replace(/\s+/g, '_')] = binding.custom || binding.default;
      return acc;
    }, {});

    await this.updateShortcut(updatedData);

    console.log('Settings saved successfully.');
    this.closeSidebar();
  } catch (error) {
    console.error('Error saving settings:', error);
  }
}
,
    async loadShortcuts() {
      try {
        await this.fetchShortcuts();
        this.keyBindings = Object.entries(this.shortcuts).map(([action, shortcut]) => ({
          action: action.replace(/_/g, ' '),
          default: shortcut,
          custom: shortcut,
        }));
      } catch (error) {
        console.error('Error fetching shortcuts:', error);
      }
    },
  },
  mounted() {
    this.loadShortcuts();
  },
};
</script>
