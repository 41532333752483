<template>
  <div
    class="px-14 w-[100vw] min-h-16 flex justify-between items-center bg-gray-900 shadow-black shadow-2xl animate-fade-down animate-delay-200"
  >
    <a
      href="/admin/projects"
      class="rounded-full shadow-black shadow-xl hover:shadow-2xl hover:shadow-white/10 flex items-center !text-gray-400 justify-center w-10 h-10 bg-gray-800 hover:bg-gray-700 transition cursor-pointer"
    >
      <ArrowLeft width="2em" hight="2em" stroke-width="2px" />
    </a>

    <div class="flex items-center gap-2">
      <div
        class="flex gap-2 px-2 py-2 font-bold !text-gray-300 rounded-xl !border !border-blue-600 hover:bg-blue-600/30 !hover:border-blue-600/60 !shadow-2xl !shadow-black !hover:shadow-gray-300/60 cursor-pointer transition"
        @click="commentDrawerVisible = true"
      >
        <ChatBubble />
      </div>
      <!-- <div -->
      <!--   class="flex gap-2 px-6 py-2 font-bold !text-gray-300 rounded-xl !border !border-blue-600 hover:bg-blue-600/30 !hover:border-blue-600/60 !shadow-2xl !shadow-black !hover:shadow-gray-300/60 cursor-pointer transition" -->
      <!-- > -->
      <!--   <UserPlus /> -->
      <!--   Share -->
      <!-- </div> -->
      <div
        class="flex gap-2 px-6 py-2 font-bold !text-gray-300 rounded-xl bg-blue-600 hover:bg-blue-600/60 !shadow-2xl !shadow-black hover:shadow-gray-300/60 cursor-pointer transition"
        @click="exportDialogVisible = true"
      >
        <CloudUpload />
        Export
      </div>
    </div>
  </div>

  <Dialog
    v-model:visible="exportDialogVisible"
    modal
    header="Export Project"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    dismissableMask
  >
    <div class="w-full mb-4">
      <!-- Dropdown for export formats -->
      <Dropdown
        v-model="selectedExportFormat"
        :options="exportFormats"
        optionLabel="label"
        optionValue="value"
        placeholder="Select format for export"
        class="w-full"
      />
    </div>

    <Button
      label="Export"
      class="p-button-primary bg-blue-600 w-full disabled:bg-gray-400"
      @click="exportProject"
      :disabled="!selectedExportFormat"
    />
  </Dialog>
  <Drawer
    v-model:visible="commentDrawerVisible"
    header="comments"
    position="right"
    class="text-gray-300 bg-gray-900 border-none w-2/5"
  >
    <template #header>
      <div class="text-xl font-bold">Comments</div>
      <div
        class="w-fit box-border px-3 py-1 ml-auto mr-2 border border-gray-400 text-gray-400 rounded-md text-sm hover:bg-gray-500/50 cursor-pointer transition-all"
      >
        Mark As Resolved
      </div>
    </template>
    <div>
      <div
        v-for="(caption, ci) in editorStore.captions.filter(
          (obj) => obj.comments.length > 0
        )"
        :key="ci"
      >
        <div class="w-full text-sm px-2 mt-8">
          {{ caption.start_timestamp }}
          <Divider />
        </div>
        <div v-for="(comment, i) in caption.comments" :key="i" class="flex m-3">
          <img
            :src="comment.user.avatar || require('@/assets/profilepic.jpg')"
            alt="pic"
            class="mr-3 w-14 h-14 rounded-full cursor-pointer"
          />
          <div class="flex flex-col">
            <div class="font-bold">{{ comment.user.name }}</div>
            <div class="text-sm text-gray-400">{{ comment.text }}</div>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script setup>
import { ArrowLeft, CloudUpload, ChatBubble } from "@iconoir/vue";
import { ref } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { useRoute } from "vue-router";
import Dropdown from "primevue/dropdown";
import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { useEditorStore } from "@/stores/editor";
import Drawer from "primevue/drawer";
import Divider from "primevue/divider";
const commentDrawerVisible = ref(false);
const editorStore = useEditorStore();

const authStore = useAuthStore();

const route = useRoute();
const projectId = route.params.id;
const exportDialogVisible = ref(false);
const selectedExportFormat = ref(null);

const exportFormats = [
  { label: "Text Document (.txt)", value: "txt" },
  { label: "PDF Document (.pdf)", value: "pdf" },
  { label: "Word Document (.docx)", value: "docx" },
  { label: "Excel (.csv)", value: "csv" },
  { label: "JSON (.json)", value: "json" },
];
const exportProject = async () => {
  if (!selectedExportFormat.value) {
    // Handle case when no format is selected
    console.error("Please select an export format.");
    return;
  }

  try {
    const exportUrl = `https://typeteam.gozar.team/api/export/${projectId}/${selectedExportFormat.value}/`;
    const response = await axios.get(exportUrl, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${authStore.token}`,
      },
    });
    const blob = response.data;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${projectId}.${selectedExportFormat.value}`; // Set the file name
    link.click(); // Trigger download
  } catch (error) {
    console.error("Failed to export project:", error);
  }
};
</script>

<!-- <style lang="scss" scoped></style> -->
