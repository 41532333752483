<template>
  <Toast />
  <div class="w-full h-full">
    <DashboardSidebar />
    <DashboardHeader />
    <router-view></router-view>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import DashboardSidebar from "@/components/DashboardSidebar.vue";
import DashboardHeader from "@/components/DashboardHeader.vue";
import { useInviteStore } from "@/stores/invite";

import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";

const toast = useToast();
const route = useRoute();
const router = useRouter();
const inviteStore = useInviteStore();

onMounted(async () => {
  const inviteToken = route.query.invite_token;
  console.log(inviteToken);
  if (inviteToken) {
    await inviteStore.acceptInvite(inviteToken);
    if (inviteStore.invitationStatus == "success") {
      toast.add({
        severity: "success",
        summary: "Accepted",
        detail: "invite link accepted",
        life: 3000,
      });
      router.push("/admin"); // Redirect to the admin page
      setTimeout(() => {
        location.reload(); // Refresh the page after a delay
      }, 1000); // 2000 milliseconds = 2 seconds delay
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "invite error",
        life: 3000,
      });
    }
  }
  router.push("/admin/projects");
});
</script>
