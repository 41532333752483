// stores/auth.js

import { defineStore } from "pinia";
import axios from "axios"; // Ensure axios is installed and configured for your backend base URL
const api = axios.create({
  baseURL: "https://typeteam.gozar.team", // Replace with your backend URL
});

export const useAuthStore = defineStore("auth", {
  state: () => ({
    email: "",
    name: "",
    organization: "",
    how_did_you_find_us: "",
    password: "",
    avatar: null, // Stores user avatar/profile picture
    token: localStorage.getItem("accessToken") || "",
    refreshToken: localStorage.getItem("refreshToken") || "",
    errorMessage: null,
    userInfo: {}, // Store user details
  }),

  actions: {
    async initAuth() {
      if (this.token) {
        await this.fetchUserInfo();
      }
    },
    async step1Email() {
      this.errorMessage = null;
      try {
        const response = await axios.post("/auth/register/step1/", {
          email: this.email,
        });
        this.token = response.data.token; // Store the token for step 2
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "empty or invalid format of email";
      }
    },

    // Step 2: Complete registration with user details
    async completeRegistration() {
      this.errorMessage = null;

      try {
        const response = await axios.post("/auth/register/step2/", {
          name: this.name,
          organization: this.organization,
          how_did_you_find_us: this.how_did_you_find_us,
          password: this.password,
          token: this.token,
          refreshToken: this.refreshToken,
        });
        if (response.data.access_token) {
          this.token = response.data.access_token;
          localStorage.setItem("accessToken", this.token);

          await this.fetchUserInfo();
        }
      } catch (error) {
        this.errorMessage = error.response?.data?.detail || "empty fields";
      }
    },
    async login() {
      this.errorMessage = null;

      this.loading = true;
      this.errorMessage = null; // Reset error message
      try {
        const response = await axios.post("/auth/login/", {
          email: this.email,
          password: this.password,
        });

        this.token = response.data.access_token; // Store the access token
        this.refreshToken = response.data.refresh_token;

        // make login persistant and set token in local storage
        localStorage.setItem("accessToken", this.token);
        localStorage.setItem("refreshToken", this.refreshToken);

        await this.fetchUserInfo();
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Invalid email or password";
      } finally {
        this.loading = false;
      }
    },
    async passwordResetRequest() {
      this.errorMessage = null;

      this.loading = true;
      try {
        await axios.post("/auth/password-reset/", {
          email: this.email,
        });
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to send reset email";
        throw error;
      } finally {
        this.loading = false;
      }
    },

    // Password reset confirmation action
    async passwordResetConfirm(uid, token, newPassword) {
      this.errorMessage = null;
      try {
        await axios.post(`/auth/password-reset/${uid}/${token}/`, {
          new_password: newPassword,
        });
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to reset password.";
        throw error;
      }
    },

    async fetchUserInfo() {
      try {
        const response = await axios.get("/auth/user/", {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.userInfo = response.data; // Save the fetched user info
        this.name = this.userInfo.name; // Map fields
        this.organization = this.userInfo.organization;
        this.email = this.userInfo.email;
        this.avatar = response.data.avatar;
        // Add additional fields like avatar, etc., if required
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to fetch user info";
      }
    },
    async updateUserInfo(updatedData) {
      try {
        const response = await axios.put("/auth/user/", updatedData, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.userInfo = response.data; // Update local state with updated info
        this.name = this.userInfo.name;
        this.email = this.userInfo.email;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to update user info";
      }
    },

    // Change profile picture
    async changeProfilePicture(file) {
      const formData = new FormData();
      formData.append("avatar", file);

      try {
        await axios.put("/auth/user/", formData, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });

        const info = await axios.get("/auth/user/", {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.avatar = info.data.avatar;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to update profile picture";
      }
    },

    async deleteProfilePicture() {
      try {
        await axios.put(
          "/auth/user/",
          { avatar: "" }, // Assuming the backend sets avatar to null on deletion
          { headers: { Authorization: `Bearer ${this.token}` } }
        );
        this.avatar = ""; // Clear avatar locally
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to delete profile picture";
      }
    },
    async googleAuth(payload) {
      try {
        const response = await axios.post("/auth/google/auth/", payload);
        // Save the token if needed
        this.accessToken = response.data.access;
        this.refreshToken = response.data.refresh;
        return response;
      } catch (error) {
        this.errorMessage = error.response?.data?.message || "Sign-Up failed";
        throw error;
      }
    },
    async logout() {
      try {
        await axios.post("/auth/logout/", null, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        // Clear local state and storage
        this.token = "";
        this.userInfo = {};
        this.email = "";
        this.name = "";
        this.organization = "";
        this.avatar = null;
        this.Token = "";
        this.refreshToken = "";
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      } catch (error) {
        this.errorMessage = error.response?.data?.detail || "Failed to log out";

        this.token = "";
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      }
    },
    isAuthenticated() {
      return this.token !== "";
    },
  },
});
// // Axios interceptor for refreshing token on 403 errors
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const authStore = useAuthStore();

    if (error.response.status === 401 && error.config && !error.config._retry) {
      error.config._retry = true;

      try {
        // Use the refresh token to get a new access token
        const refreshResponse = await axios.post("/auth/refresh/", {
          refresh: authStore.refreshToken,
        });

        // Update the access token in the store and localStorage
        authStore.token = refreshResponse.data.access_token;
        localStorage.setItem("accessToken", authStore.token);

        // Retry the original request with the new token
        error.config.headers["Authorization"] = `Bearer ${authStore.token}`;
        return api.request(error.config);
      } catch (refreshError) {
        authStore.logout();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
