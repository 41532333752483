<script setup>
import { ref, watch, onMounted } from "vue";

import Dialog from "primevue/dialog";
import Select from "primevue/select";
import Button from "primevue/button";
import {
  MacOptionKey,
  Pause,
  Play,
  Search,
  Forward15Seconds,
  Backward15Seconds,
  Undo,
  Redo,
  Translate,
} from "@iconoir/vue";
import { useSongStore } from "../stores/audio";
import Slider from "primevue/slider";
import { storeToRefs } from "pinia";
import { useProjectStore } from "@/stores/project";
import ShortcutsSidebar from "@/components/shortcutsSidebar.vue";
import { secsToDuration } from "@/utils";
import { useShortcutStore } from "@/stores/shortcut";
import { useEditorStore } from "@/stores/editor";
import { useKeyboardShortcuts } from "@/composables/useKeyboardShortcuts";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
const route = useRoute();
const router = useRouter();

function triggerUndo() {
  document.execCommand("undo");
  // const undoEvent = new KeyboardEvent("keydown", {
  //   key: "z",
  //   ctrlKey: true,
  //   bubbles: true,
  // });
  // document.dispatchEvent(undoEvent);
}

function triggerRedo() {
  document.execCommand("undo");
  // const redoEvent = new KeyboardEvent("keydown", {
  //   key: "y",
  //   ctrlKey: true,
  //   bubbles: true,
  // });
  // document.dispatchEvent(redoEvent);
}
const id = route.params.id;
const translateSelectedLanguage = ref(null);
const translateDialogVisible = ref(false);
const langs = ref([
  { name: "English", code: "EN" },
  { name: "Spanish", code: "SP" },
  { name: "Persian", code: "FA" },
]);
const translateProject = async () => {
  await projectStore.translateProject(id, translateSelectedLanguage.value.name);
  router.push(`/admin/projects`);
};

const findAndReplaceDialogVisible = ref(false);
const findAndReplaceState = ref({
  word: null,
  updated_word: null,
});
const findAndReplaceProject = async () => {
  await projectStore.findAndReplace(
    id,
    findAndReplaceState.value.word,
    findAndReplaceState.value.updated_word
  );
  await projectStore.fetch(id);
  await editorStore.fetch(id);
  window.location.reload();
};

const shortcutStore = useShortcutStore();

const useSong = useSongStore();
const projectStore = useProjectStore();
const editorStore = useEditorStore();

const { isPlaying, audio } = storeToRefs(useSong);

let isShortcutsSidebarOpen = ref(false);
let isHover = ref(false);
let isTrackTimeCurrent = ref(null);
let isTrackTimeTotal = ref(null);
let seeker = ref(0);
// let seekerContainer = ref(null)
let range = ref(0);

const isInputFocused = () => {
  const activeElement = document.activeElement;
  return (
    activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA"
  );
};
const shortcutActions = {
  undo: () => {},
  redo: () => {},
  play_pause: (event) => {
    if (isInputFocused()) return;
    event.preventDefault();
    playFunc();
    // Add your tab key logic here
  },
  rewind: (event) => {
    if (isInputFocused()) return;

    event.preventDefault();
    backward();
  },
  skip_forward: (event) => {
    if (isInputFocused()) return;
    event.preventDefault();
    forward();
  },
  find: () => {},
  mark_paragraph_completed: () => {},
  select_next_speaker: () => {},
  open_speaker_selector: () => {},
  toggle_fullscreen: () => {},
};
const playFunc = () => {
  useSong.playOrPauseThisSong(projectStore.file);
  return;
};

const toggleShortcutsSidebar = () => {
  isShortcutsSidebarOpen.value = !isShortcutsSidebarOpen.value;
};

onMounted(async () => {
  await shortcutStore.fetchShortcuts();
  var shortcuts = {};
  for (const action in shortcutStore.shortcuts) {
    shortcuts[shortcutStore.shortcuts[action]] = shortcutActions[action];
  }
  useKeyboardShortcuts(shortcuts);
  if (audio.value) {
    setTimeout(() => {
      timeupdate();
      loadmetadata();
    }, 300);
  }
});

const backward = () => {
  if (audio.value.currentTime < 15) {
    audio.value.currentTime = 0;
  } else {
    audio.value.currentTime -= 15;
  }
};
const forward = () => {
  if (audio.value.duration - audio.value.currentTime > 15) {
    audio.value.currentTime += 15;
  }
};
const timeupdate = () => {
  audio.value.addEventListener("timeupdate", function () {
    isTrackTimeCurrent.value = secsToDuration(audio.value.currentTime);
    const value = (100 / audio.value.duration) * audio.value.currentTime;
    range.value = value;
    seeker.value = value;
  });
};
const onSeekerChange = (value) => {
  audio.value.currentTime = value * (audio.value.duration / 100);
};

const loadmetadata = () => {
  audio.value.addEventListener("loadedmetadata", function () {
    const duration = audio.value.duration;
    isTrackTimeTotal.value = secsToDuration(duration);
  });
};

watch(
  () => audio.value,
  () => {
    timeupdate();
    loadmetadata();
  }
);

watch(
  () => isTrackTimeCurrent.value,
  (time) => {
    if (time && time == isTrackTimeTotal.value) {
      audio.value.pause();
      audio.value.currentTime = 0;
    }
  }
);
watch(
  () => isHover.value,
  () => {
    if (isHover.value) {
      audio.value.pause();
      isPlaying.value = false;
    }
  }
);
</script>

<template>
  <Slider
    v-model="seeker"
    :model-value="seeker"
    :onmousedown="(isHover = true)"
    :onmouseup="(isHover = false)"
    @change="onSeekerChange"
    :min="0"
    :max="100"
    step="0.001"
    :pt="{
      range: 'bg-blue-500',
      // handle: '!bg-transparent !hover:bg-transparent !cursor-default',
      root: 'bg-gray-700',
    }"
    class="w-full animate-fade-up animate-delay-1000 z-10 -mb-1"
  />
  <div
    class="w-full h-32 bg-gray-900 animate-fade-up animate-delay-1000 flex justify-between items-center px-8"
  >
    <div class="flex gap-5 items-center">
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="backward()"
      >
        <Backward15Seconds />
      </div>
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="playFunc()"
      >
        <Play v-if="!isPlaying" class="transition" />
        <Pause v-else class="transition" />
      </div>
      <div
        class="bg-blue-600 rounded-full p-2 text-gray-300 hover:bg-blue-600/80 transition cursor-pointer"
        @click="forward()"
      >
        <Forward15Seconds />
      </div>
    </div>
    <div class="flex !text-gray-300 items-center gap-2">
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
        @click="triggerUndo"
      >
        <Undo />
      </div>
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
        @click="triggerRedo"
      >
        <Redo />
      </div>
      <div
        class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
        @click="translateDialogVisible = true"
      >
        <Translate />
      </div>
      <!-- <div -->
      <!--   class="rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition" -->
      <!-- > -->
      <!--   <ClosedCaptionsTag /> -->
      <!-- </div> -->
      <div
        class="flex items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
        @click="toggleShortcutsSidebar"
      >
        <MacOptionKey />
        Shortcuts
      </div>
      <div
        class="flex items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
        @click="findAndReplaceDialogVisible = true"
      >
        <Search />
        Find and Replace
      </div>
      <div
        class="flex flex-row items-center gap-1 text-sm font-bold rounded-lg border-gray-400 hover:bg-gray-800 border p-1 cursor-pointer transition"
      >
        <input
          v-if="isTrackTimeCurrent"
          class="bg-transparent w-14 text-center items-center justify-center"
          v-model="isTrackTimeCurrent"
        />
        <input
          value="00:00"
          v-else
          class="bg-transparent w-14 text-center items-center justify-center"
        />
      </div>
    </div>
  </div>
  <ShortcutsSidebar
    v-if="isShortcutsSidebarOpen"
    @close="toggleShortcutsSidebar"
  />

  <!-- translateion dialog -->
  <Dialog
    modal
    header="Translate Project"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    v-model:visible="translateDialogVisible"
  >
    <Select
      v-model="translateSelectedLanguage"
      class="mb-5"
      :options="langs"
      :pt="{
        dropdown: 'bg-transparent',
        label: 'bg-transparent text-gray-300',
        listContainer: ' !text-gray-300',
        list: '!text-gray-300',
        overlay: 'bg-gray-900',
        root: 'bg-transparent',
        optionLabel: 'text-gray-300',
        option: 'hover:bg-gray-700 ',
      }"
      optionLabel="name"
      placeholder="Select a Language"
    />
    <Button
      label="submit"
      class="bg-blue-600 hover:bg-blue-500 !px-4 !py-2 disabled:bg-gray-400"
      @click="translateProject"
      :disabled="translateSelectedLanguage == null"
    />
  </Dialog>
  <!-- find and replace -->
  <Dialog
    modal
    header="Find and Replace"
    :pt="{
      root: 'bg-gray-900 border-none w-2/5 text-gray-400 p-8',
      header: 'm-5',
      content: 'mx-5 flex flex-col items-center justify-center my-5',
      mask: '!bg-black/50 backdrop-blur-sm',
    }"
    v-model:visible="findAndReplaceDialogVisible"
  >
    <div class="w-full flex items-center gap-4 p-3 box-border">
      <FloatLabel variant="on">
        <InputText id="word" v-model="findAndReplaceState.word" />
        <label for="word">Word</label>
      </FloatLabel>
      <FloatLabel variant="on">
        <InputText
          id="updated_word"
          v-model="findAndReplaceState.updated_word"
        />
        <label for="updated_word">Updated Word</label>
      </FloatLabel>
    </div>
    <Button
      label="submit"
      class="bg-blue-600 hover:bg-blue-500 !px-4 !py-2 disabled:bg-gray-400"
      @click="findAndReplaceProject"
      :disabled="
        findAndReplaceState.word == null ||
        findAndReplaceState.updated_word == null
      "
    />
  </Dialog>
</template>

<style>
:root {
  --p-slider-handle-content-background: #00000000;
  --p-slider-handle-content-hover-background: #00000000;
}

.circle {
  width: 4px;
  height: 4px;
  background-color: rgb(189, 189, 189);
  border-radius: 100%;
}
</style>
