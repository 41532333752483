<template>
<Toast/>
  <div class="page-container">
    <div class="content-container">
      <div class="logo">
        <h1>
          <img
            style="vertical-align: middle;"
            src="@/assets/logo.svg"
            alt="typeteam logo"
            class="icon"
          />
          typeteam
        </h1>
        <h2 style="color: #ffffff; font-size: 20px">New Password</h2>
        <p style="color: #8d8d8d">
          Please enter your new password.
        </p>
      </div>
      <div class="form">
        <label for="password" class="label">Password</label>
        <input
          type="password"
          v-model="password"
          placeholder="Choose a strong password"
          :class="['input-field', { 'input-error': isPasswordInvalid }]"
        />

        <div class="password-requirements">
          <p><strong>Password should contain:</strong></p>
          <ul>
            <li>At least 8 characters</li>
            <li>Upper and lower case letters</li>
            <li>A symbol (@$%)</li>
          </ul>
        </div>

        <label for="confirmPassword" class="label">Confirm Password</label>
        <input
          type="password"
          v-model="confirmPassword"
          placeholder="Enter your password again"
          class="input-field"
        />
      </div>

      <button @click="resetPassword" class="sign-in-button">
        Confirm
      </button>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from '@/stores/auth';
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import '@/styles/login.css';

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute(); 

const password = ref('');
const confirmPassword = ref('');


const toast = useToast();

const isPasswordInvalid = ref(false); // State to track password validation

const uid = route.query.uid;
const token = route.query.token;

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$%]).{8,}$/;
  return passwordRegex.test(password);
};
const resetPassword = async () => {
  if (!validatePassword(password.value)) {
    isPasswordInvalid.value = true;
    toast.add({
      severity: 'error',
      summary: 'Invalid Password',
      detail: 'Password must contain at least 8 characters, including upper and lower case letters and a symbol (@$%).',
      life: 3000,
    });
    return;
  }

  if (password.value !== confirmPassword.value) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Passwords do not match. Please try again.',
      life: 3000,
    });
    return;
  }

  try {
    await authStore.passwordResetConfirm(uid, token, password.value);
    toast.add({
      severity: 'success',
      summary: 'Success',
      detail: 'Password reset successfully!',
      life: 3000,
    });
    router.push('/sign-in'); // Redirect to the login page after success
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: authStore.errorMessage || 'Failed to reset password.',
      life: 3000,
    });
  }
};
</script>
<style scoped>

.input-error {
  border-color: #FB432C;
  border-width: 2px;
}
</style>
