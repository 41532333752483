<template>
  <DashboardBody>
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="w-full py-5 px-9 flex justify-between">
        <SearchBar :onChange="handleSearch" placeholder="Search Projects" />
      </div>
      <!-- Trash Projects Table -->
      <div class="w-full h-full">
        <div class="p-5">
          <DataTable
            size="large"
            :value="filteredTrash"
            paginator
            :rows="6"
            tableStyle="min-width: 50rem"
          >
            <Column field="id" header="ID"></Column>
            <Column field="title" header="Project Title"></Column>
            <Column field="status" header="Status"></Column>
            <Column field="duration" header="Length"></Column>
                        <Column header="Date Added">
              <template #body="slot">
                {{ trimStringAtT(slot.data.created_at) }}
              </template>
            </Column>
            <Column header="Last Edit">
              <template #body="slot">
                {{ trimStringAtT(slot.data.updated_at) }}
              </template>
            </Column>            <Column header="Action">
              <template #body="slotProps">
                <Button
                  icon="pi pi-refresh"
                  class="p-button-rounded p-button-success mr-2"
                  @click="restore(slotProps.data.id)"
                />
                <Button
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger"
                  @click="deletePermanently(slotProps.data.id)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </DashboardBody>
</template>


<script setup>
import { onMounted, ref, computed } from "vue";
import { useProjectStore } from "@/stores/project";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import DashboardBody from "@/components/DashboardBody.vue";
import SearchBar from "@/components/SearchBar.vue";
import {useWorkspaceStore} from "@/stores/workspace"
import {
  trimStringAtT
} from "@/utils";
const projectStore = useProjectStore();
const workspaceStore = useWorkspaceStore();
const projects = ref([]);

// Function to fetch deleted projects
async function fetchTrash() {
  if (!workspaceStore.id) {
    console.error("No active workspace selected");
    return;
  }
  const workspaceId = workspaceStore.id;
  await projectStore.fetchDeletedProjects(workspaceId);
  projects.value = projectStore.trash;
}

const searchQuery = ref('');

// Filtered collaborators
const filteredTrash = computed(() => {
  if (!searchQuery.value.trim()) {
    return projectStore.trash;
  }
  return projectStore.trash.filter(t =>
    t.title.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Handle search input changes
const handleSearch = (query) => {
  searchQuery.value = query;
};

async function restore(projectId) {
  try {
    await projectStore.restoreProject(projectId);
    projectStore.trash = projectStore.trash.filter(
      (project) => project.id !== projectId
    );  } catch (error) {
    console.error("Failed to restore project:", error.message);
  }
}


async function deletePermanently(projectId) {
  try {
    await projectStore.permanentlyDeleteProject(projectId);
    projectStore.trash = projectStore.trash.filter(
      (project) => project.id !== projectId
    );   } catch (error) {
    console.error("Failed to delete project:", error.message);
  }
}

onMounted(() => {
  fetchTrash();
});

</script>


<style>
:root {
  --p-menu-item-color: #6f767e;
  --p-paginator-nav-button-selected-background: #292929;
  --p-datatable-header-border-color: #292929;
  --p-datatable-header-cell-background: #00000000;
  --p-datatable-row-background: #00000000;
  --p-datatable-header-cell-color: #6f767e;
  --p-datatable-row-color: #F1F2F3;
  --p-datatable-border-color: #272B30;
}

.p-paginator {
  @apply bg-transparent text-gray-400 my-3
}

.p-paginator-first {
  @apply hover:bg-transparent
}

.p-paginator-last {
  @apply hover:bg-transparent
}

.p-paginator .p-paginator-page {
  @apply hover:bg-gray-800/40 rounded-lg text-gray-400
}

::v-deep .p-selectbutton {
  background-color: #00000000 !important;
}
</style>
