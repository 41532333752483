import { defineStore } from "pinia";
import axios from "axios";
import { useAuthStore } from "./auth";

export const useProjectStore = defineStore("project", {
  state: () => {
    return {
      file: null,
      title: null,
      language: null,
      projects: null,
      current_project: null,
      duration: null,
      service: null,
      service_type: null,
      errorMessage: null,
      is_loading: false,
      status: null,
      trash: null,
    };
  },
  actions: {
    async fetch(id) {
      const authStore = useAuthStore();
      this.is_loading = true;
      const response = await axios.get(`/project/${id}/`, {
        headers: {
          Authorization: `Bearer ${authStore.token}`,
        },
      });
      this.title = response.data.title;
      this.file = response.data.file;
      this.language = response.data.language;
      this.duration = response.data.duration;
      this.service = response.data.service;
      this.service_type = response.data.service_type;
      this.status = response.data.status;
      this.is_loading = false;
    },
    async fetchProjects(workspace_id, status = null) {
      const authStore = useAuthStore();
      this.is_loading = true;
      if (status == null) {
        const responselist = await axios.get(
          `/workspace/${workspace_id}/projects/`,
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.projects = responselist.data.reverse();
      } else {
        const responselist = await axios.get(
          `/workspace/${workspace_id}/projects/?status=${status}`,
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.projects = responselist.data.reverse();
      }
      this.is_loading = false;
    },
    async updateTitle(id, title) {
      const authStore = useAuthStore();
      const response = await axios.put(
        `/project/${id}/`,
        {
          title,
          language: this.language,
          service_type: this.service_type,
          service: this.service,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
      this.title = response.data.title;
    },
    async updateStatus(id, status) {
      const authStore = useAuthStore();
      await axios.put(
        `/project/${id}/`,
        {
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );
    },
    async createProject(
      workspace_id,
      file_id,
      title,
      service,
      service_type,
      language
    ) {
      const authStore = useAuthStore();
      this.is_loading = true;
      try {
        if (service === "subtitle") {
          service = "caption";
        }
        const response = await axios.post(
          "/project/",
          {
            workspace: workspace_id,
            file: file_id,
            title,
            service,
            service_type,
            language,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        this.current_project = response.data;
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to create project";
        throw error;
      }
      setTimeout(() => (this.is_loading = false), 2000);
    },
    async fetchDeletedProjects(workspace_id) {
      const authStore = useAuthStore();
      this.is_loading = true;
      try {
        const response = await axios.get(`/project/${workspace_id}/trash/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });
        this.trash = response.data.reverse(); // Assuming data is an array
      } catch (error) {
        this.errorMessage =
          error.response?.data?.detail || "Failed to fetch deleted projects";
      }
      this.is_loading = false;
    },
    async deleteProject(id) {
      const authStore = useAuthStore();
      try {
        const response = await axios.delete(`/project/${id}/`, {
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error("Error deleting project:", error);
        throw error;
      }
    },

    async restoreProject(projectId) {
      const authStore = useAuthStore();
      try {
        const response = await axios.put(
          `/project/${projectId}/`,
          { is_deleted: false },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error restoring project:", error);
        throw error;
      }
    },
    async permanentlyDeleteProject(projectId) {
      const authStore = useAuthStore();
      try {
        const response = await axios.delete(
          `/project/${projectId}/permanently-delete/`,
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error permanently deleting project:", error);
        throw error;
      }
    },
    async translateProject(id, language) {
      const authStore = useAuthStore();
      try {
        const response = await axios.post(
          `/project/${id}/translate/`,
          {
            language,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error permanently translating project:", error);
        throw error;
      }
    },

    async findAndReplace(id, word, updated_word) {
      const authStore = useAuthStore();
      try {
        const response = await axios.post(
          `/project/${id}/replace/`,
          {
            word,
            updated_word,
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error permanently translating project:", error);
        throw error;
      }
    },
  },
});
